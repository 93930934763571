<template>
  <div class="navigator-text-container">
      <div class="navigator-text-top">
          <div 
              class="navigator-text-top-btn" 
              :class="{ 'selected': selectedIndex === 'text' }"
              @click="selectItem('text')">
              <el-icon style="height: 20px;width: 20px;"><Menu /></el-icon>
              <span>文字</span>
          </div>
          <div 
              class="navigator-text-top-btn"
              :class="{ 'selected': selectedIndex === 'sticker' }"
              @click="selectItem('sticker')">
              <el-icon style="height: 20px;width: 20px;"><Menu /></el-icon>
              <span>贴纸</span>
          </div>
      </div>
      <div class="navigator-text-content">
                  <!-- 创建文本 区域按钮-->
        <div style="margin-bottom: 20px;display: flex;justify-content: left;">
          <i class="iconfont icon-wenbenkuang text-icon-style" @click="createHtmlElement"></i>
        </div>
        <div class="text-content-container">
          <h3>文本内容</h3>
          <div class="text-input-container">
            <el-input 
              size="large" 
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="textStyle.textContent"
              @input="updateTextStyle" 
            ></el-input>
          </div>
        </div>
        <div class="text-style-container">
          <h3>文本样式</h3>
          <!-- 文本样式选择器 -->
          <div>
            <el-select
              placeholder="Select"
              size="large"
              style="width: 100%"
              v-model="textStyle.fontFamily"
              @change="updateTextStyle"
              >
              <el-option
                v-for="item in fontFamilys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :style="{ fontFamily: item.value}"
              />
            </el-select>
          </div>
          <!-- 文本粗细选择器 -->
          <div class="select-bold-class">
            <div style="flex: 1;">
              <el-select
                placeholder="Select"
                size="large"
                style="width: 100%"
                v-model="textStyle.fontWeight"
                @change="updateTextStyle"
                >
                <el-option
                  v-for="item in fontWeights"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :style="{ fontWeight: item.fontWeight}"
                />
              </el-select>
            </div>
            <div style="flex: 1;margin-left: 10px">
              <el-input-number @change="updateTextStyle" size="large" v-model="textStyle.fontSize"/>
            </div>
          </div>
          <!-- 布局 -->
          <div class="select-layout-class">
            <!-- 布局方式 -->
            <div style="flex: 2;">
              <el-radio-group v-model="textStyle.textAlign" size="large" class="my-el-radio-style" @change="changeLayout">
                <el-radio-button 
                  v-for="item in layout" 
                  :key="item.label" 
                  :label="item.label" 
                  :value="item.value"
                  >
                  <i :class="['iconfont', item.icon]" style="font-weight: bolder;"></i>
                </el-radio-button>
              </el-radio-group>
            </div>

            <div style="flex: 1;display: flex;justify-content: right">
              <el-radio-group v-model="textStyle.textDecoration" size="large" class="my-el-radio-style" @change="updateTextStyle">
                <el-radio-button 
                  v-for="item in UnderlineOrStrikethrough" 
                  :key="item.label" 
                  :label="item.label" 
                  :value="item.value">
                  <i :class="['iconfont', item.icon]" style="font-weight: bolder;"></i>
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <!-- 间距 -->
          <div class="select-gap-class">
            <!-- 行间距 -->
            <div class="line-gap-class">
              <h4>行间距</h4>
              <el-input size="large" v-model="textStyle.lineHeight" @input="updateTextStyle"/>
            </div>
            <!-- 字间距 -->
            <div class="letter-spacing-class">
              <h4>字间距</h4>
              <el-input  size="large" v-model="textStyle.letterSpacing" @input="updateTextStyle"/>
            </div>
          </div>
          <!-- 颜色 -->
          <div style="margin-top: 20px;">
            <h4 style="margin: 0;margin-bottom: 5px">颜色</h4>
            <div class="select-color-class">
              <div class="text-color" style="margin-right: 5px;">
                <span>文字颜色</span>
                <el-color-picker 
                  v-model="textStyle.color" 
                  show-alpha 
                  :predefine="predefineColors"
                  :popper-options="popperTextColorOptions"
                  @change="updateTextStyle"/>
              </div>
              <div class="text-color" style="margin-left: 5px;">
                <span>文字底色</span>
                <el-color-picker 
                  v-model="textStyle.backgroundColor" 
                  show-alpha 
                  :predefine="predefineColors"
                  :popper-options="popperBackgroundOptions"
                  @change="updateTextStyle"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { Menu } from '@element-plus/icons';
import { ElInput,ElSelect,ElOption,ElInputNumber,ElRadioGroup,ElRadioButton,ElColorPicker,ElMessage } from 'element-plus';
import { addElementDragEvent , setSelectedElementStyle} from '@/utils/commonFunctions';
export default {
    name: "navigator-text",
    components:{
      Menu,
      ElInput,
      ElSelect,
      ElOption,
      ElInputNumber,
      ElRadioGroup,
      ElRadioButton,
      ElColorPicker,
    },
    data() {
        return {
          selectedIndex:'text',
          // 字体样式选择
          // TODO:换成开源文本样式库
          fontFamilys: [
              { value: 'Alibaba PuHuiTi', label: 'Alibaba PuHuiTi' },
              { value: 'Alibaba HeiTi', label: 'Alibaba HeiTi' },
              { value: 'Alibaba DongFangDaKai', label: 'Alibaba DongFangDaKai' },
              { value: 'Alibaba DaoKaiTi', label: 'Alibaba DaoKaiTi' },
              { value: 'Georgia', label: 'Georgia' },
              { value: 'Courier New', label: 'Courier New' },
              { value: 'Garamond', label: 'Garamond' },
              { value: 'Tahoma', label: 'Tahoma' },
              { value: 'Trebuchet MS', label: 'Trebuchet MS' },
              { value: 'Comic Sans MS', label: 'Comic Sans MS' },
              { value: 'Lucida Console', label: 'Lucida Console' },
              { value: 'Impact', label: 'Impact' },
              { value: 'Roboto', label: 'Roboto' }, 
              { value: 'Open Sans', label: 'Open Sans' },
              { value: 'Lato', label: 'Lato' }, 
              { value: 'Montserrat', label: 'Montserrat' } 
          ],
          // 是否加粗选择
          fontWeights:[
            {
              value:"bold",
              label:"Bold",
              fontWeight:"700"
            },
            {
              value:"normal",
              label:"Regular",
              fontWeight:"normal"
            },
            {
              value:"bolder",
              label:"UltraBold",
              fontWeight:"900"
            }
          ],
          textStyle:{
            // 内容
            textContent:"文本",
            // 字体样式
            fontFamily:"Arial",
            // 是否加粗
            fontWeight:"bold",
            // 布局
            textAlign:"Align Left",

            // 下划线或者删除线
            textDecoration:"",
            // 字体大小
            fontSize:24,
            // 行间距
            lineHeight:24,
            // 字间距
            letterSpacing:1.5,
            // 字体颜色
            color:"rgba(250, 212, 0, 1)",
            // 字体底色
            backgroundColor:"rgba(255, 255, 255, 0.03)",

          },
          // 文字布局方式
          layout:[ 
            { label:"1",value:"Align Left" ,icon:"icon-zuoduiqi"}, // 左对齐
            { label:"2",value:"Align Right" ,icon:"icon-youduiqi"}, // 右对齐
            { label:"1",value:"Center",icon:"icon-juzhongduiqi" }, // 居中
            { label:"1",value:"Justify",icon:"icon-liangduanduiqimohangzuoduiqi" }, // 两端对我
          ],
          // 下划线删除线
          UnderlineOrStrikethrough:[
            // 下划线
            { label:1 , value:"underline",icon:"icon-xiahuaxian"},
            // 删除线
            { label:2 , value:"line-through",icon:"icon-shanchuxian"},
          ],
          // 预定义颜色
          predefineColors:[
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585',
            'rgba(255, 69, 0, 0.68)',
            'rgb(255, 120, 0)',
            'hsv(51, 100, 98)',
            'hsva(120, 40, 94, 0.5)',
            'hsl(181, 100%, 37%)',
            'hsla(209, 100%, 56%, 0.73)',
            '#c7158577',
            '#FFFFFF'
          ],
          // 自定义颜色选择弹窗出现的位置
          popperTextColorOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [30, 10]  // 控制弹出框的偏移量
                }
              }
            ]
          },
          popperBackgroundOptions:{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-130, 10]  // 控制弹出框的偏移量
                }
              }
            ]
          },
          selectedTextId:null
        }
    },
    methods: {
      selectItem(item){
        if(item == "text"){
          return
        }
        this.$router.push("/generate/sticker")
      },
      // 改变布局
      changeLayout(value){
        console.log("selected layout:",value)
      },
      // 更新字体样式
      updateTextStyle(){
        // 行间距和字体大小一致
        this.textStyle.lineHeight = this.textStyle.fontSize
        const container = document.getElementById("container")
        const text = container.querySelector(".DT-text-container.selected")
        if(text){
          this.setTextStyle(null,text.id)
        }
      },
      
      // 设置文本内容的样式 element:文本内容，p标签 ，textId选中的文本框【文本框里面包含p标签】
      setTextStyle(element = null, textId = null) {
        // 如果没有传入 element，且传入了 textId，则根据 textId 获取元素
        if (!element && textId) {
          element = document.getElementById(textId).querySelector('p');
        }
        // 如果没有有效的元素（element），则退出
        if (!element) {
          // 在文本框<div></div>下面获取显示文本的p标签
          console.warn("P Element not found.");
          return;
        }
        // 设置p标签文本内容
        element.textContent = this.textStyle.textContent;
        // 设置样式
        for (let key in this.textStyle) {
          if (Object.prototype.hasOwnProperty.call(this.textStyle, key)) {
            // 处理 fontSize 的单位
            if (key === "fontSize" || key === "lineHeight" || key === "letterSpacing") {
              element.style[key] = this.textStyle[key] + "px";
            } else {
              element.style[key] = this.textStyle[key];
            }
          }
        }
        // 额外设置 margin 样式
        element.style.margin = "0";
      },
      //节流函数，限制缩放触发次数，让字体缩放变慢
      throttle(fn, wait) {
        let lastTime = 0;
        return function(...args) {
          const now = Date.now();
          if (now - lastTime >= wait) {
            fn.apply(this, args);
            lastTime = now;
          }
        };
      },
      // 创建文本框，并且添加拖拽移动和缩放事件
      createHtmlElement() {
        const container = document.getElementById('container');
        const div = document.createElement('div');
        div.classList.add('text-container');

        const textContainerDiv = document.createElement('div');
        textContainerDiv.classList.add('DT-text-container');

        const newElementP = document.createElement('p');
        this.setTextStyle(newElementP);  // 设置 p 标签的样式
        textContainerDiv.appendChild(newElementP);

        const textPointClasses = ["text-top-left", "text-top-right", "text-bottom-left", "text-bottom-right"];
        textPointClasses.forEach(positionClass => {
          const textDianDiv = document.createElement('div');
          textDianDiv.classList.add("text-dian", positionClass);
          textContainerDiv.appendChild(textDianDiv);

          textDianDiv.addEventListener('mousedown', (e) => {
            e.stopPropagation();

            const initialX = e.clientX;
            const initialY = e.clientY;

            const initialLeft = parseInt(div.style.left) || 0;
            const initialTop = parseInt(div.style.top) || 0;

            const mouseMoveHandler = this.throttle((e) => {
              e.stopPropagation();
              
              let deltaX = e.clientX - initialX;
              let deltaY = e.clientY - initialY;
              // 改变缩放因子，字体每次缩放的大小为鼠标移动的距离乘以scaleFactor
              const scaleFactor = 50;
              const currentFontSize = parseInt(newElementP.style.fontSize) || 16;
              // 更新字体大小
              const updateFontsize = (newFontsize) => {
                newElementP.style.fontSize = newFontsize;
                newElementP.style.lineHeight = newFontsize;
              };

              // 不同顶点的缩放逻辑
              if (positionClass === "text-top-left") {
                const newFontSize = Math.max(12, currentFontSize - deltaX / scaleFactor) + "px";
                updateFontsize(newFontSize);
                div.style.left = initialLeft + deltaX / scaleFactor + 'px';
                div.style.top = initialTop + deltaY / scaleFactor + 'px';
              }
              if (positionClass == "text-top-right") {
                const newFontSize = Math.max(12, currentFontSize + deltaX / scaleFactor) + "px";
                updateFontsize(newFontSize);
                div.style.top = initialTop - deltaY / scaleFactor + "px";
              }
              if (positionClass == 'text-bottom-left') {
                const newFontSize = Math.max(12, currentFontSize - deltaX / scaleFactor) + "px";
                updateFontsize(newFontSize);
                div.style.left = initialLeft + deltaX / scaleFactor + "px";
              }
              if (positionClass == "text-bottom-right") {
                const newFontSize = Math.max(12, currentFontSize + deltaX / scaleFactor) + "px";
                updateFontsize(newFontSize);
              }
            }, 50);  // 每 100ms 执行一次

            const mouseUpHandler = () => {
              document.removeEventListener('mousemove', mouseMoveHandler);
              document.removeEventListener('mouseup', mouseUpHandler);
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
          });
        });

        div.appendChild(textContainerDiv);
        container.appendChild(div);

        // 设置唯一ID并添加点击事件
        const uniqueId = `text-container-${Date.now()}`;
        textContainerDiv.id = uniqueId;
        textContainerDiv.addEventListener('click', () => this.selectTextDivElement(uniqueId));

        // 添加拖动事件
        addElementDragEvent(div);

        // 提示文本创建成功
        ElMessage({
          type: "success",
          message: "文本创建成功"
        });
      },
      setTextStyleFromElement(element) {
        const updatedStyle = {
          fontFamily: element.style.fontFamily || "",
          fontWeight: element.style.fontWeight || "",
          textAlign: element.style.textAlign || "",
          textDecoration: element.style.textDecoration || "",
          fontSize: parseInt(element.style.fontSize, 10) || 0,  
          lineHeight: parseInt(element.style.lineHeight, 10) || 0, 
          letterSpacing: parseFloat(element.style.letterSpacing) || 0,
          color: element.style.color || "",
          backgroundColor: element.style.backgroundColor || "",
          textContent: element.textContent || ""
        };
        this.textStyle = updatedStyle
        // 输入框内容不变
        console.log(this.textStyle,"update textStyle")
      },
      // 选中的文本框高亮显示
      selectTextDivElement(textId) {
        // 调用设置选中的样式显示边框
        this.selectedTextId = textId
        setSelectedElementStyle(textId)
        const element = document.getElementById(textId)
        // 获取文本框的p标签
        const firstPTag = element.querySelector('p');
        if (firstPTag) {
          // 如果 firstPTag 存在，才调用 setTextStyleFromElement
          // TODO：textStyle中的值被改变，输入框内容不变
          this.setTextStyleFromElement(firstPTag);
        } else {
          console.log("No p element found inside the element.");
        }
      },

    },
    mounted(){
      // 加载页面获取是否有被选中的文本，如果有则更改textStyle的默认值【文本编辑区域的初始值】
      const container = document.getElementById("container")
      const selectedText = container.querySelector(".DT-text-container.selected")
      if (selectedText){
        const pTag = selectedText.querySelector('p')
        this.setTextStyleFromElement(pTag)
      }
    }
};
</script>

<style>

.text-container{
    position: absolute;
}


.DT-text-container{
    cursor: pointer;
    display: flex;
    position: relative;
    border: 2px dashed  transparent;
}
.DT-text-container:hover {
    border-color: var(--el-color-primary);
}
.DT-text-container:hover .text-dian {
    display: block;
}
/* 选中的文本框 */
.DT-text-container.selected {
    border-color: var(--el-color-primary);;
}
.DT-text-container.selected .text-dian{
    display: block;
}
/* 文本框顶点 */
.text-dian {
    display: none;
    position: absolute;
    background-color:  white;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid black;
}

.text-top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.text-top-right {
    right: -5px;
    top: -5px;
    cursor: ne-resize;
}

.text-bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.text-bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.text-dian:hover {
    transform: scale(1.5);
}
/*  */



.text-icon-style{
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.navigator-text-container{
  width: 83%; 
  max-height: 100%; 
  display: flex; 
  flex-direction: column;
}
.navigator-text-top{
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}
.navigator-text-top-btn{
  display: flex;
  padding: 10px 20px;
  /* background-color: rgb(242, 191, 25); */
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(242, 191, 25);
  box-sizing: border-box;
}
.navigator-text-top-btn.selected {
  background-color: rgb(242, 191, 25);;
}
.navigator-text-content{
  width: 100%;
  height: 95%;
  background-color: rgb(246, 248, 250);
}

.navigator-text-content{
  background-color: white;
  width: 100%;
  height: 60%;
  margin-top: 10px;

}

.text-content-container{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.text-content-container h3{
  margin: 0;
}
.select-bold-class{
  display: flex;
  margin-top: 10px
}
.select-layout-class{
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
}
.text-input-container{
  margin-top: 20px;
  max-height: 200px;
}
.text-style-container{
  display: flex;
  flex-direction: column;
  text-align: left;
}
 .my-el-radio-style .el-radio-button__original-radio:checked+.el-radio-button__inner{
  background-color: #d2cfce !important;
  border-color:#d2cfce !important;
  box-shadow:-1px 0 0 #d2cfce;
}
.my-el-radio-style .el-radio-button--large .el-radio-button__inner{
  padding: 12px 15px !important;
}



.select-gap-class{
  display: flex;
  margin-top: 20px;
}
.select-gap-class h4{
  margin: 0;
  margin-bottom: 5px;
}

.line-gap-class{
  flex: 1;
  margin-right: 5px;
}
.letter-spacing-class{
  flex: 1;
  margin-left: 5px
}
.select-color-class{
  display: flex;
  justify-content: space-around;
}
.text-color{
  flex: 1;
  box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  border-radius: var(--el-input-border-radius,var(--el-border-radius-base));
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
}

.color-block{
  height: 30px;
  width: 30px;
  background-color: antiquewhite;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

</style>