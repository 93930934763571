<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <div style="margin-top: 50px;width: 1600px;height:350px;display: flex;" v-if="showEmpty">
        <div class="empty-container" @click="toUpload">
            <el-empty class="empty" description="暂无生成图" />
        </div>
    </div>
    <div style="margin-top: 50px;width: 1600px;height:350px;display: flex;" v-if="!showEmpty">
      <div style="display: flex;" v-for="picture in pictures" :key="picture.id" class="image-wrapper" @click="goToPreview(picture)">
        <img :src="picture.url" width="300px" height="300px"/>
        <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + picture.id" @click="deleteImgae(picture.picture_id)"><Delete/></el-icon>
        <el-icon style="top: 8px; right: 43px;" class="icon" @click="downloadAiImage(picture.id,picture.url)"><Download/></el-icon>
        <i style="right: 3%; top: 81%;">
          <button class="preview-generate-button" @click="view()">查看</button>
        </i>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { Download ,Delete} from "@element-plus/icons";
  import {ElMessage, ElMessageBox,ElEmpty} from "element-plus";
  import { getPictures,deletePicture} from "@/api";
  import { HttpCodes } from "@/common/statusCodes";
  export default {
    name: "Picture-float-layer",
    components: {
      Download,
      Delete,
      ElEmpty
    },
    data(){
      return {
        showEmpty:false,
        modalVisible: false, // 初始时 modal 不可见
        // 测试数据
        pictures:[],
      }
    },
    computed:{
      showPersonalPictureDialog(){
          return this.$store.state.user.showPersonalPictureDialog || false
      },
    },
    methods: {
      // 下载原商品提取图或者已经生成好的商品展示图
      downloadAiImage(id,url) {
        event.stopPropagation(); // 阻止事件冒泡
        const fileName = id + ".jpg"
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      deleteImgae(picture_id){
        event.stopPropagation(); // 阻止事件冒泡
        ElMessageBox.confirm(
          '删除之后，将无法恢复，是否确认删除？',
          'Warning',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            lockScroll:false,
          }
        ).then(() => {
            deletePicture(picture_id)
            .then(response => {
              if(response.status == HttpCodes.SUCCESS){
                ElMessage({
                  message:"删除成功",
                  type:"success"
                })
                // 获取新的前四个商品图
                this.getPicturesList()
              }else{
                // 删除错误的提示
                console.log(response)
              }
              })
              .catch(error => {
                console.error('Error deleting image:', error);
              });
          })
          .catch(() => {
            ElMessage({
              message:"已取消删除操作"
            })
        })
      },
      goToPreview(picture){
        // 导航至picturepreview
        this.$store.commit("setSelectPicture", picture)
        this.$router.push("/picturepreview")
      },
      getPicturesList(){
        getPictures({
          page:1,
          page_size:4
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            // TDDO:后端需要分页来处理Pitures
            this.pictures = response.data.data.pictures;
            if(this.pictures.length == 0){
                this.showEmpty = true
                // 不显示站位
              }else{
                this.showEmpty = false
              }
          }
        }).catch(error => {
          console.log(error)
        })
      },
      view(){

      }
    },
    mounted(){
      this.getPicturesList()
    }
  };
  </script>
  
  <style>
.empty-container{
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
}
.empty{
  width: 300px;
  height: 300px;
  background-color: #e4e4e6;
}


  .image-wrapper {
    /* margin: auto !important; */
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
  }
  .image-wrapper img {
    width: 300px;
    height: 300px;
  }
  
  .image-wrapper:hover {
    cursor: pointer;
  }
  
  .image-wrapper:hover::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    height: 300px;
    top: 0;
    width: 300px;
    z-index: 99;
  }
  .icon {
    padding: 5px;
    display: none;
    position: absolute;
    height: 20px !important;
    width: 20px !important;
    background-color: rgba(0, 0, 0, 0); /* 完全透明 */
    border-radius: 5px;
    color: white;
  }
  
  .image-wrapper:hover .icon {
    display: block;
    z-index: 999;
  }
  
  .image-wrapper i {
    display: none;
  }
  
  .image-wrapper:hover i {
    display: inline-block;
    position: absolute;
    font-size: 40px;
    z-index: 999;
    color: #fff;
    top: 50%;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45px;
    right: 8px;
    border: 1px solid black;
    background-color: white;
    z-index: 999;
    border-radius: 5px;
  }
  </style>