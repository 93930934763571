<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <top-navigator>top bar</top-navigator>

    <div style="height:50px;display: flex;justify-content: space-between;margin-left: 100px;">
      <div>
        <p class="get-inspired-title">你的商品库</p>
      </div>
      <div style="margin-top: 30px;float: right;margin-right: 100px">
        <el-button type="success" plain size="large" @click="jumpToUpload">上传商品</el-button>
      </div>
    </div>
    <div style="margin-top: 20px;justify-content: left;display: flex;margin-left: 100px;">
        <p>点击一个商品开始创作</p>
    </div>
    <div class="pcituresContainer">
      <div class="search-pcitures-container">
        <el-input 
          class="my-search-input-style"
          placeholder="请输入关键字"
          v-model="searchData"
          clearable
          @keydown.enter="searchAssets"
          >
          <template #suffix>
            <el-icon style="height: 15px;width: 15px;margin-bottom: 10px;" @click="searchAssets"><Search /></el-icon>
          </template>
        </el-input>
      </div>
      <div class="gird-container">
        <div v-for="item in assetList" :key="item.asset_id" class="picture-container"  @click="goToPreview(item)">
            <img :src="item.asset_url" width="300px" height="300px"/>
            <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + item.asset_id" @click="showPopup(item.asset_id)"><More/></el-icon>
            <div class="modal" :id="'modal_' + item.asset_id" v-if="modalVisible === item.asset_id" @mouseleave="hidePopup(item.asset_id)">
              <div class="modal-item" @click="downloadFile(item.asset_id,item.asset_url)">
                  <el-icon style="height: 20px;width: 20px"><Download/></el-icon>
                  <span class="modal-item-text">下载</span>
              </div>
              <div class="modal-item">
                  <el-icon style="height: 20px;width: 20px"><Male/></el-icon>
                  <span class="modal-item-text">查看详情</span>
              </div>
              <div class="modal-item" @click="deleteProduct(item.asset_id)">
                  <el-icon style="height: 20px;width: 20px"><Delete/></el-icon>
                  <span class="modal-item-text">删除</span>
              </div>
            </div>
          <el-icon style="top: 8px; right: 43px;" class="icon" @click="downloadFile(item.asset_id,item.asset_url)"><Download/></el-icon>
          <i style="right: 3%; top: 82%;">
            <button class="preview-generate-button" @click="jumpToGenerate(item)">创作</button>
          </i>
        </div>
      </div>
    </div>
    <div class="pagination_container"> 
      <ElConfigProvider :locale="zhCn">
        <el-pagination
          :page-sizes="[10, 20, 30, 50, 100]"
          layout="total,prev,pager,next,sizes,jumper"
          :total="totalAsset"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </ElConfigProvider>
    </div>
    <ErrorDialog v-model="dialogData.isModalVisible" @confirm="confirm">
        <div style="height: 300px;display: flex;justify-content: center;align-items: center;">
          <p>{{ dialogData.errorMessage }}</p>
        </div>
    </ErrorDialog>
  </div>
</template>

<script>
import TopNavigator from "@/components/TopBar.vue";
import { ElButton ,ElMessage,ElPagination,ElMessageBox,ElConfigProvider,ElInput} from "element-plus";
import { getAssets,deleteAsset } from "@/api/index";
import { Download ,Delete,More,Male,Search} from "@element-plus/icons";
import zhCn from "element-plus/es/locale/lang/zh-cn";//！！！！！！！
import { HttpCodes } from "@/common/statusCodes";
import ErrorDialog from './templates/ErrorDialog.vue';
export default {
  name: "assets-page",
  components: {
    TopNavigator,
    ElButton,
    ElPagination,
    Download,
    Delete,
    More,
    Male,
    ElConfigProvider,
    ErrorDialog,
    ElInput,
    Search
  },
  data() {
    return{
      dialogData:{
        isModalVisible:false,
        errorImage:require("../assets/images/logo/error.jpg"),
        errorMessage:""
      },
      zhCn,
      modalVisible:null,
      searchData:"",
      assetList:[],
      totalAsset:0,
      hovered: null,
      pageData:{
        pagesize:10,
        page:1
      },
      name:"",
      selectAsset:""

    }
  },
  methods: {

    confirm(){
      this.dialogData.isModalVisible = false
    },
    // 跳转到上传到商品页面
    jumpToUpload() {
      this.$router.push("/upload");
    },
    // 点击商品跳转到创作页面
    jumpToGenerate(asset){
      event.stopPropagation()
      this.$store.commit("setSelectAsset", asset)
      this.$router.push("/generate")
    }, 
    searchAssets() {
      if (this.searchData.trim() !== "") {
        this.getAssetsList();
        this.showMessage(`已为你搜索关键字为 "${this.searchData}" 的商品`, "success");
      } else {
        this.getAssetsList()
        this.dialogData.errorMessage = "请你先输入商品图的关键字"
        this.dialogData.isModalVisible = true
      }
    },
    showMessage(message, type) {
      ElMessage({
        message: message,
        type: type,
        plain: true,
      });
    },
    getAssetsList(){
      getAssets({ 
        name:this.searchData,
        page:this.pageData.page,
        page_size:this.pageData.pagesize
      }).then(response => {
        if(response.status == HttpCodes.SUCCESS){
          this.totalAsset = response.data.data.count
          this.assetList = response.data.data.assets
        }
      }).catch(error => {
        console.error('There was an error!', error);
      })
    },
    // 改变分页大小
    handleSizeChange (page_size){
      this.pageData.pagesize = page_size
      this.getAssetsList()
    },
    // 变换页码
    handleCurrentChange(page){
      this.pageData.page = page
      this.getAssetsList()
    },
    deleteProduct(id){
      event.stopPropagation(); // 阻止事件冒泡
      ElMessageBox.confirm(
        '删除之后，将无法恢复，是否确认删除？',
        'Warning',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.selectAsset = id
        deleteAsset(this.selectAsset)
          .then(response => {
            console.log(response)
            if(response.status == HttpCodes.SUCCESS){
              this.assetList = this.assetList.filter(item => item.asset_id !== id);
              this.showMessage("删除成功","success")
            }else{
              // 删除错误的提示
              console.log(response)
            }
            })
            .catch(error => {
              console.error('Error deleting image:', error);
            });
        })
        .catch(() => {
          this.showMessage("以取消删除","info")
        })
    },
    // 下载商品图
    downloadFile(id,url){
      event.stopPropagation(); // 阻止事件冒泡
      const fileName = id + ".jpg"
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showPopup(itemId) {
      event.stopPropagation()
      if (this.modalVisible === itemId) {
        this.modalVisible = null; // 如果当前点击的和之前的相同，则关闭弹出框
      } else {
        this.modalVisible = itemId; // 否则打开对应的弹出框
      }
    },
    hidePopup() {
      this.modalVisible = null;
    },
    goToPreview(asset){
      this.$store.commit("setSelectAsset", asset)
      this.$router.push("/assetpreview")
    },
    
  },
    mounted(){
      this.getAssetsList()
    }
};
</script>

<style>
.icon {
  display: none;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 5px
}


.preview-generate-button {
  width: 80px;
  height: 30px;
  color: black;
  background-color: orange;
  border-radius: 3px;
  font-size: 17px;
  font-style: normal;
  border: None;
}
.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 8px;
  border: 1px solid black;
  background-color: white;
  z-index: 999;
  border-radius: 5px;
}
.modal-item{
  display: flex;
  padding: 10px;
  font-weight: bold;
}
.modal-item:hover {
  background-color: lightgray;
}
.modal-item-text{
  margin-left: 10px
}
.pagination_container{
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>