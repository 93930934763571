// drag.js

/**
 * 给指定的元素添加拖拽事件，使其在父容器内可拖动。
 * @param {HTMLElement} element - 需要拖拽的元素
 */
export function addElementDragEvent(element) {
    // 获取父容器
    const container = document.getElementById('container');
    let isDragging = false;
    let startX, startY, initialX, initialY;

    // 为元素添加鼠标按下事件
    element.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        initialX = parseInt(element.style.left) || 0;
        initialY = parseInt(element.style.top) || 0;
        e.preventDefault();
    });

    // 鼠标移动时更新元素位置
    document.addEventListener('mousemove', (e) => {
        if (isDragging) {
            const deltaX = e.clientX - startX;
            const deltaY = e.clientY - startY;

            let newLeft = initialX + deltaX;
            let newTop = initialY + deltaY;

            // 获取父容器的宽度和高度
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;

            // 获取元素的宽度和高度
            const elementWidth = element.offsetWidth;
            const elementHeight = element.offsetHeight;

            // 限制元素位置不超出父容器的范围
            if (newLeft < 0) newLeft = 0;
            if (newTop < 0) newTop = 0;
            if (newLeft > containerWidth - elementWidth) newLeft = containerWidth - elementWidth;
            if (newTop > containerHeight - elementHeight) newTop = containerHeight - elementHeight;

            // 更新元素位置
            element.style.left = newLeft + 'px';
            element.style.top = newTop + 'px';
        }
    });

    // 鼠标松开时结束拖拽
    document.addEventListener('mouseup', () => {
        isDragging = false;

        // const position = {
        //     left: element.style.left,
        //     top: element.style.top,
        // };
        // console.log(element.id + " position:", position);
    });
}
// 设置选择的对象显示边框
export function setSelectedElementStyle(elementUniqueId){
    // 获取此次选择的对象
    const selectedElement = document.getElementById(elementUniqueId)
    // 获取透明区域
    const container = document.getElementById('container');
    // 查询出透明区域所有对象
    const allElements = container.querySelectorAll(".DT-sticker-container,.DT-text-container,#asset")
    // 初始化对象，默认都不显示边框
    Array.from(allElements).forEach(el => el.classList.remove('selected'));
    // 设置此次选择的对象显示边框
    selectedElement.classList.add('selected');
    
}
