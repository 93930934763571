<template>
    <div class="reset-container">
        <div class="reset-item">   
            <el-form 
                :model="currentResetForm" 
                ref="currentResetForm" 
                :rules="resetItem === 'password' ? resetPasswordFormRules : resetEmailRules"
                :validate-on-rule-change="false"
                >
                <div v-if="resetItem == 'password'">
                    <el-form-item label="输入旧密码" prop="oldPassword" class="my-el-form-item-style" v-if="passwordExists">
                        <el-input 
                            v-model="currentResetForm.oldPassword" 
                            placeholder="请输入旧密码" 
                            style="width: 300px;" 
                            type="password"
                            show-password
                            size="large"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="输入新密码" prop="newPassword" class="my-el-form-item-style">
                        <el-input 
                            v-model="currentResetForm.newPassword" 
                            placeholder="11-20位数字和字母组合" 
                            style="width: 300px;" 
                            type="password"
                            show-password
                            size="large"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="输入旧密码" prop="confirmNewPassword" class="my-el-form-item-style">
                        <el-input 
                            v-model="currentResetForm.confirmNewPassword" 
                            placeholder="确认新密码" 
                            style="width: 300px;" 
                            type="password"
                            show-password
                            size="large"
                        ></el-input>
                    </el-form-item>
                </div>
                <div v-if="resetItem == 'email'">
                    <el-form-item label="请输入邮箱" prop="email" class="my-el-form-item-style">
                        <el-input 
                            v-model="currentResetForm.email" 
                            placeholder="请输入邮箱" 
                            style="width: 300px;" 
                            size="large"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="输入验证码" prop="code" class="my-el-form-item-style">
                        <el-input 
                            v-model="currentResetForm.code" 
                            placeholder="请输入邮箱验证码" 
                            style="width: 300px;" 
                            size="large"
                            clearable
                        ></el-input>
                    </el-form-item>
                </div>
                <el-form-item class="my-el-form-item-style">
                    <el-button type="info" style="margin-left: 96px;" @click="handleConfirmEdit">确认</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { ElForm,ElInput,ElFormItem,ElButton,ElMessage } from 'element-plus';
import { resetPassword,refreshUser } from '@/api';
import { HttpCodes } from '@/common/statusCodes';
export default {
    name:"account-reset",
    components:{
        ElInput,
        ElForm,
        ElFormItem,
        ElButton
    },
    data(){
        return {
            passwordExists:null,
            resetItem:"password",
            currentResetForm:{},
            // 修改密码验证规则
            resetPasswordFormRules:{
                oldPassword: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
                newPassword: [{ required: true, validator: this.passwordValidator, trigger: "blur" }],
                confirmNewPassword: [{ required: true, validator: this.confirmPasswordValidator, trigger: "blur" }]
            },
            // 修改邮箱验证规则
            resetEmailRules:{
                email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
                code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
            }
        }
    },
    methods:{
        // 密码格式效验回调
        passwordValidator(rule, value, callback) {
            const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{11,20}$/;
            if (!value) {
                callback(new Error("请输入新密码"));
            } else if (!passwordRegex.test(value)) {
                callback(new Error("密码必须为11-20位字母和数字组合"));
            } else {
                callback();
            }
        },
        // 确认密码回调
        confirmPasswordValidator(rule, value, callback){
            if (value !== this.currentResetForm.newPassword) {
                callback(new Error("两次输入密码不一致"));
            } else {
                callback();
            }
        },
        // 确认编辑
        handleConfirmEdit(){
            this.$refs.currentResetForm.validate((valid) =>{
                if(valid){
                    if(this.resetItem == "password"){
                        // 充值密码请求
                        resetPassword(this.currentResetForm).then(response => {
                            if(response.status == HttpCodes.SUCCESS){
                                ElMessage({
                                    message:"修改成功",
                                    type:"success"
                                })
                                this.currentResetForm.oldPassword = ""
                                this.currentResetForm.newPassword = ""
                                this.currentResetForm.confirmNewPassword = ""
                            }

                        }).catch(error => {
                            ElMessage({
                                message:error.response.data.data.error,
                                type:"warning"
                            })
                        })
                        
                    }
                }
            })
        },
        // 获取要修改的Form
        async getResetForm(){
            await this.checkPassword();
            const resetItem = this.$store.state.user.editItem
            // 如果resetItem为password返回修改密码表单
            // 如果resetItem为email返回修改email的表单
            const resetMap = {
                'password':{ oldPassword:"", newPassword:"", confirmNewPassword:""},
                'email':{ email:"", code:""},
            }
            this.resetItem = resetItem
            this.currentResetForm = resetMap[resetItem]
            if(!this.passwordExists){
                delete this.currentResetForm.oldPassword
                delete this.resetPasswordFormRules.oldPassword
            }
        },
        async checkPassword() {
            try {
                const user_id = JSON.parse(localStorage.getItem("userInfo")).user_id;
                const response = await refreshUser(user_id);
                console.log(!!response.data.data.password)

                this.passwordExists = !!response.data.data.password;
            } catch (error) {
                console.log(error);
            }
        }

    },
    mounted(){
        this.getResetForm()
        
    },    
    computed:{

    },
}
</script>

<style>
.reset-container{
    display: flex;
    width: 40vw;
    max-width: 1600px;
    height: 500px;
    margin: 0 auto;
    flex-direction: column;

}
.reset-item{
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    height: 50%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
/* #f7f9fb */
.my-el-form-item-style .el-form-item__label{
    font-size: 15px;
    display: flex;
    margin-top: 5px;
}
.my-el-form-item-style .el-form-item__content .el-button--info{
    background-color: #e3e5e7;
}
.my-el-form-item-style .el-form-item__content .el-button--info span{
    color: rgb(109, 108, 108);
}
</style>