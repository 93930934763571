<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
    <div id="app">
        <top-navigator>top bar</top-navigator>
        <div class="infoContainer">
            <PersonalNavigator></PersonalNavigator>
            <div class="infoRight">
                <div class="rightTop">
                    <div class="bread">
                        <span class="bread-span bread-span-weight" @click="goOrderList()">我的订单</span>
                        <span class="bread-span" v-if="showOrderDetail" @click="goOrderDetail()">订单详情</span>
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import TopNavigator from "@/components/TopBar.vue";
import PersonalNavigator from "./templates/PersonalNavigator.vue";
export default {
    name: "profile-page",
    components:{
        TopNavigator,
        PersonalNavigator

    },
    data() {
        return {
            
        };
    },
    methods:{
        goOrderList(){
            this.$router.push("/personal/order/list")
        },
        goOrderDetail(){
            this.$router.push("/personal/order/detail")
        },
    },
    computed:{
        showOrderDetail(){
            return this.$route.path == "/personal/order/detail"
        }
    },
    mounted(){

    }
};
</script>

<style>
html, body, #app {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
}
.isBule{
    color: blue;
}
.infoRight {
    padding: 5px 0px 20px 20px;
    box-sizing: border-box;
}
.infoContainer {
    display: flex;
    height: 94.5%;
}
.bread{
    display: flex;
    width: 400px;
    height: 50px;
    align-items: center;
    line-height: 50px
}
.bread-span{
    margin: 0;
    margin-right: 10px;
}
.bread-span-weight {
    font-weight: bolder;
    font-size: larger;
}
.bread span+span::before{
    content: "/";
    padding-right: 10px
}
.bread-span:hover {
    cursor: pointer;
    color: blue;
}

</style>
