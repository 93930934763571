<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <top-navigator ref="child">top bar</top-navigator>
    <el-carousel indicator-position="outside" :interval="10000">
        <el-carousel-item v-for="item in 3" :key="item">
          <div class="inner_container">
            <span class="home_title">productpix<p style="margin: 0;">"内测中、尽情期待!"</p></span>
            <div class="span_style">
                <span>AI赋能、</span>
                <span>高效出图、</span>
                <span>助力大卖</span>
            </div>
            <div class="btn_items">
              <div class="register_btn">
                <span class="btn_span" style="color: blue;" @click="register">立即注册</span>
              </div>
              <div class="detail_btn">
                <span class="btn_span" style="color: white;" @click="toDetail">了解详情</span>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="footer">
        <a href="https://beian.miit.gov.cn" class="footer-link" target="_blank">
          ©2023-2024 深圳市鹏然信息技术有限责任公司 粤ICP备2024286710号-1
        </a>
    </div>
  </div>
</template>

<script>
import TopNavigator from "@/components/TopBar.vue";
import { ElCarousel,ElCarouselItem } from "element-plus";

export default {
  name: "home-page",
  components: {
    TopNavigator,
    ElCarousel,
    ElCarouselItem,
  },

  data() {
    return {};
  },

  methods: {
    register(){
      // 获取TopNavigator组件中的数据,来控制登录框的显示
      if(localStorage.getItem('userInfo')){
        return
      }else{
        this.$refs.child.dialogVisible = true
      }
    },
    toDetail(){
      return
    },

  },
  mounted() {
  },
};
</script>

<style>
  .el-carousel__container{
    height: 60vh !important;
  }
  .inner_container {
    margin-left: 300px;
    margin-top: 200px
  }
  .home_title{
    display: flex;
    background: none;
    margin-bottom: 20px !important;
    font-size: 50px;
    color: white;
    border: none;
  }
  .span_style{
    display: flex;
    justify-content: left;
    color: white;
    margin-left: 5px;
  }
  .btn_items {
    display: flex;
    margin-top: 30px
  }
  
  .btn_span{
    padding: 10px 30px 10px 30px;
    font-size: small;
  
  }
  .register_btn{
    background: white;
    display: flex;
  }
  .register_btn:hover{
    cursor: pointer;
    background: rgb(216, 214, 214);
  }
  .detail_btn{
    margin-left: 20px;
    display: flex;
    border: 1px solid white;
  }
  .detail_btn:hover{
    cursor: pointer;
    background: #0b4796;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #c7a121;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #124e9c;
  }
  .el-carousel__indicators--outside {
    bottom: 10px;
    text-align: center;
    position: absolute !important;
    transform: none;
}
.footer {
  padding-bottom: 5px;
  background: #272D36;
  display: flex;
}

.footer-link {
    color: white;
    font-size: small;
    text-decoration: none;
    
    line-height: normal; 
    border: none;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
  }
  .footer-link:hover{
    cursor: pointer;
    color: green;
    background: none;
  }

</style>
