<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
      <div class="wx_login_box" v-if="show_success">
          <div>
            <div v-if="showCountDown">
              <p>二维码将在 <span style="color: blue;">{{ minutes }}:{{ seconds }}</span> 后失效，请及时扫描登录！</p>
            </div>
            <p>使用微信扫码完成登录</p>
            <img :src="code_url" alt="">
          </div>
          <div class="btns">
            <div  class="btn back_btn" @click="goBack">返回</div>
            <div class="success_btn btn"><span style="color: white;"  @click="finish">我已扫描成功</span></div>
          </div>
      </div>
      <div style="margin-top: 200px" v-if="!show_success">
          <img src="../assets/images/logo/pay_seccess_logo.png" alt="" style="height: 100px;width: auto;">
          <h1 style="margin: 30px">登陆成功</h1>
      </div>
  </div>
</template>
<script>
import { getWxCodeUrl,wxloginPoll } from '@/api';
import { ElMessageBox } from 'element-plus';
import { HttpCodes } from '@/common/statusCodes';
export default {
  name: "PayPage",
  data(){
      return{
          code_url:"",
          show_success:true,
          scene_str:"",
          timeout: 600, // 十分钟六百秒
          interval: null,
          showCountDown:false
      }
  },
  mounted(){
    this.getQRCode()
  },
  methods: {
      goBack(){
        clearInterval(this.interval)
        clearInterval(this.tem_poll)
        this.$router.push("/home")
      },
      finish(){
          this.show_success = false
      },
      // 获取登陆二维码
      getQRCode(){
        getWxCodeUrl().then(response =>{
          this.code_url = response.data.data.qr_code_url
          this.showCountDown = true
          this.startCountdown()
          this.scene_str = response.data.data.scene_str
          // 开启前端轮询定时器
          this.tem_poll = setInterval(this.loginPoll, 1000)
        }).catch(error => {
          console.log(error)
        })
      },
      
      loginPoll() {
        wxloginPoll({
          scene_str:this.scene_str
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            // 清除定时器
            clearInterval(this.tem_poll)
            clearInterval(this.interval)
            const { drawsettings, ...userInfo } = response.data;
            const removedKeyValuePair = { drawsettings };
            const now = Date.now();
            // 超时时间为refresh_token的有效时间，后端refresh_token配置的三天之内有效
            const millisecondsInThreeDays = 3 * 24 * 60 * 60 * 1000;
            const timestampThreeDaysLater = now + millisecondsInThreeDays;
            console.log(timestampThreeDaysLater)
            userInfo.timeout = timestampThreeDaysLater
            localStorage.setItem("userInfo",JSON.stringify(userInfo))
            localStorage.setItem("drawsettings",JSON.stringify(removedKeyValuePair.drawsettings))
            this.$router.push("/personal").then(() => {
                window.location.reload();
            });
          }
        }).catch(error =>{
          console.log(error)
        })
      },
      startCountdown() {
      this.interval = setInterval(() => {
        if (this.timeout > 0) {
          this.timeout--;
        } else {
          clearInterval(this.interval);
          this.timeout = 0;
          ElMessageBox.confirm(
            "二维码已失效是否刷新?",
            "消息提示",{
              confirmButtonText: '确定',
              customClass: 'custom-message-box',
              showCancelButton: true, // 显示取消按钮
            }).then(() => {
              // 刷新验证码
              this.getQRCode()
              // 重置倒计时
              this.timeout = 600
            }) .catch(() =>{
              // 取消登录push到home页面
              this.$router.push("/home")
            })
        }
      }, 1000);
    },
  },
  computed:{
    minutes() {
      return Math.floor(this.timeout / 60);
    },
    seconds() {
      return this.timeout % 60 < 10 ? '0' + this.timeout % 60 : this.timeout % 60;
    },
  }
}

</script>

<style>
.wx_login_box{
  text-align: center;
  align-items: center;
  flex-direction: row;
}
.wx_login_box img {
  display: inline-block;
  width: 300px;
  height: auto;
}
.btn {
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 添加过渡效果，使颜色变化更平滑 */
}
.back_btn{
  border: 1px solid black;
  padding: 5px 20px;
  margin-right: 40px
}
.success_btn{
  background-color: blue;
  padding: 5px 10px
}

.btn:hover {
  background-color: lightblue; /* 鼠标悬停时的背景色 */
  color: white; /* 文本颜色变为白色 */
}
</style>