<template>
    <div class="navigator-text-container">
        <div class="navigator-text-top">
            <div 
                class="navigator-text-top-btn" 
                :class="{ 'selected': selectedIndex === 'text' }"
                @click="selectItem('text')">
                <el-icon style="height: 20px;width: 20px;"><Menu /></el-icon>
                <span>文字</span>
            </div>
            <div 
                class="navigator-text-top-btn"
                :class="{ 'selected': selectedIndex === 'sticker' }"
                @click="selectItem('sticker')">
                <el-icon style="height: 20px;width: 20px;"><Menu /></el-icon>
                <span>贴纸</span>
            </div>
        </div>
        <div class="navigator-sticker-content">
            <div class="sticker-list-container" v-if="!showAllStickers">
                <div class="sticker-item" v-for="item in stickers" :key="item.id">
                    <div class="sticker-container-info">
                        <span>{{ item.label }}</span>
                        <div class="sticker-btn-container" @click="ViewAllStickers(item.name)">
                            <span>全部</span>
                            <el-icon class="point-right-icon"><ArrowRight /></el-icon>
                        </div>
                    </div>
                    <div class="sticker-container-content">
                        <div 
                            class="sticker" 
                            v-for="sticker in item.value" 
                            :key="sticker.id" 
                            @click="createStcikerElement(sticker.url)"
                            >
                            <img :src="sticker.url" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sticker-list-container" v-if="showAllStickers">
                <div class="sticker-goBack-btn">
                    <el-icon 
                        style="height: 20px;width: 20px;font-size: large;font-weight: bold;cursor: pointer;" 
                        @click="colseShowStickersList"
                        ><Back/>
                    </el-icon>
                    <span>{{ viewAllStickersList.label }}</span>
                </div>
                <div class="show-stickers-list">
                    <div v-for="item in viewAllStickersList.value" :key="item.id">
                        <img :src="item.url" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { Menu,ArrowRight,Back } from '@element-plus/icons';
  import { addElementDragEvent,setSelectedElementStyle } from '@/utils/commonFunctions';
  export default {
      name: "navigator-sticker",
      components:{
        Menu,
        ArrowRight,
        Back
      },

      data() {
          return {
            selectedIndex:'sticker',
            // 所有的贴纸信息
            stickers:[
                { 
                    id:1, 
                    name:"DiscountInformations",
                    label:"折扣信息",
                    value: [
                        { id: 'DiscountInformation-sticker-1', url: require("../../assets/sticker/DiscountInformation/d45b78adb689426983e923fcf970bf48.png") },
                        { id: 'DiscountInformation-sticker-2', url: require("../../assets/sticker/DiscountInformation/dd46b116871c499586cc3ba38055fa19.png") },
                        { id: 'DiscountInformation-sticker-3', url: require("../../assets/sticker/DiscountInformation/e1854d23b54e4b27a06f539957dc4252.png") },
                        { id: 'DiscountInformation-sticker-4', url: require("../../assets/sticker/DiscountInformation/f21fc1a420184f44822b62354644e29f.png") },
                    ]
                },
                { 
                    id:2, 
                    name:"NewProductsLaunched",
                    label:"新品上线",
                    value: [
                        { id: 'NewProductsLaunched-sticker-1', url: require("../../assets/sticker/NewProductsLaunched/76dba68d284c444aad47617dc335d1f2.png") },
                        { id: 'NewProductsLaunched-sticker-2', url: require("../../assets/sticker/NewProductsLaunched/582e476feb6249c3bd415a37b732a315.png") },
                        { id: 'NewProductsLaunched-sticker-3', url: require("../../assets/sticker/NewProductsLaunched/078840ed9c7844298fbad817c6e7e412.png") },
                        { id: 'NewProductsLaunched-sticker-4', url: require("../../assets/sticker/NewProductsLaunched/e5f2411fd4e04c99beae40a0bcbb02e9.png") },
                    ]
                },
                { 
                    id:3, 
                    name:"HotSaleProducts",
                    label:"热卖商品",
                    value: [
                        { id: 'HotSaleProduct-sticker-1', url: require("../../assets/sticker/HotSaleProducts/0b26f66638e146af91b6b7c86b875104.png") },
                        { id: 'HotSaleProduct-sticker-2', url: require("../../assets/sticker/HotSaleProducts/02b950c68c45401989e08de1f8e024c6.png") },
                        { id: 'HotSaleProduct-sticker-3', url: require("../../assets/sticker/HotSaleProducts/03ad8e026dd241b4afcd2fa56412ee46.png") },
                        { id: 'HotSaleProduct-sticker-4', url: require("../../assets/sticker/HotSaleProducts/d5377a8c584b4ca6935a8bd0356ec7c8.png") },
                    ]
                },
                { 
                    id:4, 
                    name:"OrderNow",
                    label:"立即下单",
                    value: [
                        { id: 'OrderNow-sticker-1', url: require("../../assets/sticker/OrderNow/7fe259ecc89d4a25a0137664dbb04377.png") },
                        { id: 'OrderNow-sticker-2', url: require("../../assets/sticker/OrderNow/8bda006eba0e49d79a35ffddf3be0cfd.png") },
                        { id: 'OrderNow-sticker-3', url: require("../../assets/sticker/OrderNow/a44220513c2948039d2faa4d6f087820.png") },
                        { id: 'OrderNow-sticker-4', url: require("../../assets/sticker/OrderNow/ecf7819827e84feea086322af8817a39.png") },
                    ]
                }
            ],
            // 查看某一个分类的所有贴纸
            viewAllStickersList:[],
            showAllStickers:false,
          }
      },
    methods:{
        selectItem(item){
            if(item == "sticker"){
                return
            }
            this.$router.push("/generate/layers")
        },
        // 查看某一个贴纸分类
        ViewAllStickers(sticker){
            this.showAllStickers = true
            // 筛选分类
            this.viewAllStickersList = this.stickers.filter(item => item.name == sticker)
            this.viewAllStickersList = this.viewAllStickersList[0]
        },
        // 返回
        colseShowStickersList(){
            this.showAllStickers = false
        },
        // 在透明区域创建贴纸元素传入贴纸的url
        createStcikerElement(imageUrl){
            // 获取父容器
            const container = document.getElementById('container');
            // 贴纸区域最外层，因为需要移动则需要将div的style的position设置为absolute
            const div = document.createElement('div')
            div.classList.add('sticker-container')
            // 创建stickerContainer，因为有顶点显示，需要将position设置为relative
            const stickerContainerDiv = document.createElement('div')
            // 创建img标签
            const stickerImg = document.createElement('img');
            stickerImg.src = imageUrl
            stickerImg.style.width = 100 + "px"
            stickerImg.style.height = 'auto'; // 设置高度为自动（根据宽度自动调整）
            // 将img添加到stickerContainerDiv中
            stickerContainerDiv.appendChild(stickerImg)
            // 创建点
            stickerContainerDiv.classList.add('DT-sticker-container');
            // 贴纸的唯一ID
            const uniqueId = `sticker-container-${Date.now()}`;
            stickerContainerDiv.id = uniqueId
            stickerContainerDiv.addEventListener('click', () => setSelectedElementStyle(uniqueId));
            // 贴纸顶点l类【每个点不同位置】
            const stickerPointClasses = ["sticker-top-left", "sticker-top-right", "sticker-bottom-left", "sticker-bottom-right"];
            // 循环创建四个点
            for (let i = 0; i < 4; i++) {
                // 创建一个新的 div 元素
                const stickerDianDiv = document.createElement('div');
                
                // 添加两个类：共同的类 'sticker-dian' 和循环中每个 div 的类
                stickerDianDiv.classList.add("sticker-dian", stickerPointClasses[i]);

                // 将新创建的 div 元素添加到父容器中
                stickerContainerDiv.appendChild(stickerDianDiv)

                // 为每个点添加缩放事件
                stickerDianDiv.addEventListener('mousedown', (e) => {
                    e.stopPropagation();

                    const initialWidth = parseInt(stickerImg.style.width);
                    const initialHeight = parseInt(stickerImg.style.height);
                    const initialX = e.clientX;
                    const initialY = e.clientY;

                    // 记录初始坐标
                    const initialLeft = parseInt(div.style.left) || 0;
                    const initialTop = parseInt(div.style.top) || 0;

                    const positionClass = stickerDianDiv.classList[1];

                    // 鼠标移动事件
                    const mouseMoveHandler = (e) => {
                        e.stopPropagation()
                        // 鼠标移动记录
                        let deltaX = e.clientX - initialX;
                        let deltaY = e.clientY - initialY;
                        // 以左上顶点进行缩放
                        if (positionClass === "sticker-top-left") {
                            const newWidth = Math.max(initialWidth - deltaX, 50);
                            const newHeight = Math.max(initialHeight - deltaY, 50);
                            // 更新宽度和高度
                            stickerImg.style.width = newWidth + 'px';
                            stickerImg.style.height = newHeight + 'px';
                            // 更新左上角的位置，保持左边不变
                            div.style.left = initialLeft + deltaX + 'px';
                            div.style.top = initialTop + deltaY + 'px'
                        }
                        // 以右上进行缩放
                        if (positionClass === "sticker-top-right") {
                            // 根据鼠标水平移动的距离 deltaX 计算新的宽度
                            const newWidth = Math.max(initialWidth + deltaX, 50);  
                            // 根据鼠标垂直移动的距离 deltaY 计算新的高度
                            const newHeight = Math.max(initialHeight - deltaY, 50);

                            // 更新图片的宽度和高度
                            stickerImg.style.width = newWidth + 'px';
                            stickerImg.style.height = newHeight + 'px';
                            // 根据鼠标移动更新图片的顶部位置（使图片上边缘跟随鼠标移动）
                            div.style.top = initialTop + deltaY + "px";
                        }
                        // 以左下进行缩放
                        if (positionClass === "sticker-bottom-left") {
                            // 根据鼠标水平移动的距离 deltaX 计算新的宽度
                            const newWidth = Math.max(initialWidth - deltaX, 50);  
                            // 根据鼠标垂直移动的距离 deltaY 计算新的高度
                            const newHeight = Math.max(initialHeight + deltaY, 50);

                            // 更新图片的宽度和高度
                            stickerImg.style.width = newWidth + 'px';
                            stickerImg.style.height = newHeight + 'px';

                            // 更新左下角的位置，保持左边不变
                            div.style.left = initialLeft + deltaX + 'px';
                        }
                        // 以右下进行缩放
                        if (positionClass === "sticker-bottom-right") {
                            const newWidth = Math.max(initialWidth + deltaX, 50);
                            const newHeight = Math.max(initialHeight + deltaY, 50);
                            // 更新宽度和高度
                            stickerImg.style.width = newWidth + 'px';
                            stickerImg.style.height = newHeight + 'px';
                        }
                    };
                    // 鼠标松开事件
                    const mouseUpHandler = () => {
                        document.removeEventListener('mousemove', mouseMoveHandler);
                        document.removeEventListener('mouseup', mouseUpHandler);
                    };

                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                });

            }
            // 将stickerContainerDiv添加到最外层div
            div.appendChild(stickerContainerDiv)
            // 将div添加到透明背景区域container中
            container.appendChild(div)
            // 为贴纸添加拖动事件
            addElementDragEvent(div)
        },

    },
    mounted(){
        
    }
  };
  </script>
  
<style>

/* 动态生成贴纸样式 */
.sticker-container{
    position: absolute;
}


.DT-sticker-container {
    position: relative;
    border: 2px dashed transparent;
    display: flex;
    cursor: pointer;
}

.DT-sticker-container:hover {
    border-color: black;
}
/* 选中的贴纸*/
.DT-sticker-container.selected {
    border-color: black;
}
.DT-sticker-container.selected .sticker-dian{
    display: block;
}

.DT-sticker-container:hover .sticker-dian {
    display: block;
}

.sticker-dian {
    display: none;
    position: absolute;
    background-color:  white;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid black;
}

.sticker-top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.sticker-top-right {
    right: -5px;
    top: -5px;
    cursor: ne-resize;
}

.sticker-bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.sticker-bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.sticker-dian:hover {
    transform: scale(1.5);
}



.navigator-sticker-content{
    width: 100%;
    height: 95%;
}
.sticker-container-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sticker-container-info span{
    font-size: large;
}
.sticker-btn-container{
    display: flex;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
}
.sticker-item{
    margin-bottom: 15px;
}
.sticker-btn-container span{
    color: #a7a9aa;
    font-size: large;
}
.sticker-btn-container .point-right-icon{
    height: 15px;
    width: 15px;
    margin-left: 5px;
    margin-top: 2px;
    color: #a7a9aa;
}
.sticker-btn-container:hover .point-right-icon {
    cursor: pointer;
    color: blue;
}
.sticker-btn-container:hover span {
    cursor: pointer;
    color: blue;
}
.sticker-container-content{
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 10px; 
    justify-content: start;
    margin-top: 10px;
}
.sticker {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e0e1e3;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    aspect-ratio: 1 / 1;
}
.sticker:hover {
    border-color: #f6de60;
}
.sticker img {
    width: 100%;
    /* height: auto; */
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
}

.sticker-goBack-btn{
    display: flex;
    margin-bottom: 20px;
    justify-content: left;
    align-items: center;
}
.sticker-goBack-btn span{
    font-size: large;
    margin-left: 5px;
}

.show-stickers-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px; 
    justify-content: start;
    margin-top: 10px;
    width: 70%;
}
.show-stickers-list div{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e0e1e3;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    aspect-ratio: 1 / 1;
}
.show-stickers-list img {
    height: auto; /* 保持图片比例 */
    object-fit: cover; /* 图片自适应填充 */
    cursor: pointer;
    max-width: 100%;      /* 限制最大宽度 */
    max-height: 100%;     /* 限制最大高度 */
}

  
</style>