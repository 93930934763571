<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div style="height: 100%">
    <div style="display: flex; justify-content: right; margin-bottom: 15px">
      <el-icon
        class="close-button"
        style="height: 30px; width: 30px"
        @click="closeThePage()"
        ><CloseBold
      /></el-icon>
    </div>
    <div style="display: flex">
      <div class="upload-container">
        <div style="display: flex; flex-direction: column; text-align: left">
          <h1 style="margin-top: 0">请选择图片</h1>
          <el-upload
            drag
            ref="uploadAsset"
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :auto-upload="false"
            :on-change="justSelect"
          >
            <div class="avatar-wrapper">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <div v-if="imageUrl" class="overlay"></div>
              <el-icon v-if="!imageUrl" class="avatar-uploader-icon"
                ><Plus
              /></el-icon>
              <div class="upload-prompt" v-if="!imageUrl">
                <span>点击上传商品图</span>
                <p style="font-size: small">图片大小不超过10MB</p>
              </div>
            </div>
          </el-upload>
        </div>
        <div class="input-groups">
          <div class="input-group" style="margin-left: 0px">
            <h1 style="margin-top: 5px">物品名称</h1>
            <span class="absolute-span" v-if="isRequired">*</span>
            <el-input
              type="textarea"
              placeholder="请输入物品名称，必选"
              v-model="formData.name"
              :autosize="{ minRows: 10, maxRows: 10 }"
              clearable
              class="asset-name-input"
              @blur="inputAssetName()"
              :style="
                isRequired
                  ? { border: '1.5px solid red', borderRadius: '5px' }
                  : { border: '1.5px solid var(--el-color-info-light-5)', borderRadius: '5px' }
              "
            />
            <p style="color: red; font-size: small">{{ inputError }}</p>
          </div>
          <div class="input-group" style="margin-top: 30px">
            <h1>物品描述</h1>
            <el-input
              type="textarea"
              :autosize="{ minRows: 15, maxRows: 15 }"
              placeholder="请输入物品的详细描述"
              v-model="formData.decription"
            >
            </el-input>
          </div>
          <div class="input-group" style="margin-top: 30px">
            <el-button
              type="primary"
              @click="onSubmit"
              size="large"
              :disabled="isUploading"
              >上传</el-button
            >
            <el-button type="info" @click="close" plain size="large"
              >取消选择</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <ErrorDialog v-model="dialogData.isModalVisible" @confirm="confirm">
      <div style="height: 300px">
        <img
          :src="dialogData.errorImage"
          alt=""
          style="width: 200px; height: 200px"
        />
        <p>{{ dialogData.errorMessage }}</p>
      </div>
    </ErrorDialog>
  </div>
</template>
<script>
import {
  ElUpload,
  ElInput,
  ElButton,
  ElMessage,
  ElLoading,
} from "element-plus";
import { Plus, CloseBold } from "@element-plus/icons";
import { addAsset } from "@/api";
import { HttpCodes } from "@/common/statusCodes";
import ErrorDialog from "./templates/ErrorDialog.vue";
export default {
  data() {
    return {
      dialogData: {
        errorImage: require("../assets/images/logo/error.jpg"),
        errorMessage: "",
        isModalVisible: false,
        confirmEvent: "",
      },
      imageUrl: "",
      formData: {
        name: "",
        file: null,
        decription: "",
        user_id: "",
      },
      // 是否正在上传
      isUploading: null,
      // 控制显示红色边框
      isRequired: true,
      inputError: "",
    };
  },
  components: {
    ElUpload,
    ElInput,
    Plus,
    ElButton,
    CloseBold,
    ErrorDialog,
  },
  methods: {
    // 点击弹框确定按钮发生的事件
    confirm() {
      if (this.dialogData.confirmEvent == "1") {
        this.dialogData.isModalVisible = false;
      }
      // 关闭打开打开文件目录
      if (this.dialogData.confirmEvent == "2") {
        this.dialogData.isModalVisible = false;
        this.$refs.uploadAsset.$el.querySelector('input[type="file"]').click();
      }
    },
    beforeAvatarUpload(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    removeExtension(filename) {
      return filename.replace(/\.[^/.]+$/, "");
    },
    // 上传前判断文件大小是否超出10MB
    justSelect(file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        // 弹窗提示
        (this.dialogData.errorMessage = "照片大小不能超过10MB"),
          (this.dialogData.confirmEvent = "1");
        this.dialogData.isModalVisible = true;
      } else {
        this.formData.file = file.raw;
        this.imageUrl = URL.createObjectURL(file.raw);
        // 去掉文件后缀名
        const filename = this.removeExtension(file.name);
        this.formData.name = filename;
        // 默认上传商品名称为照片名称，执行合法检测
        this.inputAssetName();
      }
    },
    // 上传事件
    onSubmit() {
      if (this.formData.file) {
        const formData = new FormData();
        formData.append("name", this.formData.name);
        formData.append("image", this.formData.file);
        formData.append("description", this.formData.decription);
        // 显示上传中
        const loading = ElLoading.service({
          lock: true,
          text: "上传中...",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const timeout = 30000;
        const uploadTimeoutId = setTimeout(() => {
          loading.close();
          ElMessage({
            message: "请求超时请重试",
            type: "info",
          });
        }, timeout);
        this.isUploading = true;
        addAsset(formData)
          .then((response) => {
            if (response.status == HttpCodes.CREATED) {
              this.close();
              this.showMessage("已成功上传", "success");
              // 导航至首页
              this.$router.push("/personal");
              this.isUploading = false;
              // 清除定时器
              clearTimeout(uploadTimeoutId);
              loading.close();
            } else {
              // 清除定时器
              loading.close();
              clearTimeout(uploadTimeoutId);
              this.isUploading = false;
              this.showMessage("上传失败,请检查后端代码", "warning");
            }
          })
          .catch((error) => {
            console.log("upload raw asset image error", error);
            loading.close();
            clearTimeout(uploadTimeoutId);
            this.isUploading = false;
            this.dialogData.confirmEvent = "1";
            this.dialogData.errorMessage = "上传失败，请稍后重试!";
            this.dialogData.isModalVisible = true;
          });
      } else {
        this.dialogData.confirmEvent = "2";
        this.dialogData.errorMessage = "请先选择一个商品图片";
        this.dialogData.isModalVisible = true;
      }
    },
    // 取消选择
    close() {
      this.imageUrl = "";
      this.formData.file = null;
      this.formData.decription = "";
      this.formData.name = "";
      this.inputError = "";
      this.isRequired = true;
    },
    // 关闭当前页面
    closeThePage() {
      // 返回到来时的页面
      this.$router.go(-1);
    },
    showMessage(msg, type) {
      ElMessage({
        message: msg,
        type: type,
      });
    },
    inputAssetName() {
      // 最大字符数
      const maxLength = 50;
      // 允许的字符集，包括中文
      const validCharsRegex = /^[a-zA-Z0-9_\u4e00-\u9fa5 ]*$/;

      // 如果输入为空，设置必填标志并返回
      if (this.formData.name.trim() === "") {
        this.isRequired = true;
        this.inputError = "名称为必填项";
        return;
      }
      // 检查字符合法性
      if (!validCharsRegex.test(this.formData.name)) {
        this.setError("请输入只包含字母、数字、空格和下划线的名称");
        return;
      }
      // 检查字符长度
      if (this.formData.name.length > maxLength) {
        this.setError("请输入50以内的字符名称");
        return;
      }
      // 如果所有检查都通过，重置错误信息
      this.inputError = "";
      this.isRequired = false;
    },

    setError(message) {
      this.inputError = message;
      this.isRequired = true;
    },
  },
};
</script>
<style>
.upload-container {
  display: flex;
  height: 80%;
  margin-left: 30px;
  margin: 0 auto;
}
.close-button {
  height: 30px;
  width: 30px;
}
.close-button {
  cursor: pointer;
}
.avatar-uploader .el-upload {
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-wrapper {
  position: relative;
  width: 775px;
  height: 775px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}

.avatar {
  width: 100%;
  height: auto;
  display: block;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}
.avatar-wrapper:hover .overlay {
  opacity: 1;
}
.input-groups {
  margin-left: 100px;
}
.input-group {
  position: relative;
  text-align: left;
  width: 600px;
}
.absolute-span {
  color: red;
  font-weight: bolder;
  font-size: 30px;
  position: absolute;
  top: 60px;
  left: -15px;
}
.avatar-uploader .el-upload .el-upload-dragger {
  padding: 0 !important;
}
.upload-prompt {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 180px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
}

.upload-prompt p {
  margin: 10px;
}
/* 取出默认样式 */
.asset-name-input .el-textarea__inner {
  box-shadow: none !important;
}

</style>