<template>
    <div class="sample-grid-container">
        <div>
            <img src="../../assets/images/samples/sample1.jpg" alt="">
        </div>
        <div>
            <img src="../../assets/images/samples/sample2.jpg" alt="">
        </div>
        <div>
            <img src="../../assets/images/samples/sample3.jpg" alt="">
        </div>
        <div>
            <img src="../../assets/images/samples/sample4.jpg" alt="">
        </div>
    </div>
</template>

<script>


</script>

<style> 
.sample-grid-container {
    margin: 0 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 创建四列，每列宽度均等 */
    gap: 100px; /* 设置网格项之间的间距 */
}
.sample-grid-container div{
    font-size: 0;
    line-height: 1;
}

.sample-grid-container div img {
    width: 100%; /* 图片宽度适应父容器 */
    aspect-ratio: 1 / 1;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}


</style>