// Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved
import axios from 'axios'

const http =  axios.create({
    baseURL: 'https://www.stockeepix.com/api/',
    // 每次请求的超时时间
    timeout: 60000,
})
// 配饰请求拦截器
http.interceptors.request.use(
  config => {
      const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);

// 判断token是否过期
const isTokenExpired = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) {
      const now = Date.now();
      return now > userInfo.timeout;
  }
  return true; 
};

const logout = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("drawsettings")
  window.location.href = '/home';
}

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // 判断是不是token失效，需要刷新token
    if(error.response.status === 401 && !originalRequest._retry){
      originalRequest._retry = true;
      // 从localStorage获得refresh_token
      if(isTokenExpired()){
        logout()
      }
      const refreshToken = JSON.parse(localStorage.getItem("userInfo")).refresh_token;
      if (refreshToken) {
        try {
          // 向后端发送请求来获取新的access-token
          const response = await axios.post('https://www.stockeepix.com/api/users/refresh_token/', {
            refresh: refreshToken
          });
          // 更新access_token
          const userInfo = JSON.parse(localStorage.getItem("userInfo"))
          userInfo.token = response.data.access_token
          localStorage.setItem("userInfo",JSON.stringify(userInfo))
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
          // 从新执行上一次发生token过期的请求
          return http(originalRequest);
        } catch (e) {
          console.error(e);
        }
      }
    }
    return Promise.reject(error);
  });



export default http