<template>
    <div>
        <div class="account-settings">
            <div class="account-settings-item">
                <span class="fixed-span">手机号</span>
                <div>
                    <span v-if="infoData.mobile">{{ infoData.mobile }}</span>
                    <span v-else>请绑定手机号</span>
                    <span class="operate-btn">更换手机</span>
                </div>
            </div>
            <div class="account-settings-item">
                <span class="fixed-span">密码</span>
                <div>
                    <span class="operate-btn" @click="handleBtnEvent('password')">修改密码</span>
                </div>
            </div>
            <div class="account-settings-item">
                <span class="fixed-span">邮箱</span>
                <div>
                    <span v-if="infoData.email"></span>
                    <span v-else>存在风险，请绑定邮箱!</span>
                    <span class="operate-btn" @click="handleBtnEvent('email')">修改邮箱</span>
                </div>
            </div>
            <div class="account-settings-item">
                <span class="fixed-span">注销账号</span>
                <div>
                    <span class="operate-btn" @click="closeAccount()">立即注销</span>
                </div>
            </div>
        </div>
        <ErrorDialog v-model="dialogData.isModalVisible" @updataDialog="updataDialog" @confirm="confirm">
            <div style="height: 200px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;border-radius: 10px; padding: 20px;">
                <p style="color: red">{{ dialogData.errorMessage }}</p>
            </div>
        </ErrorDialog>
    </div>
</template>

<script>
import ErrorDialog from '../templates/ErrorDialog.vue';
import { deleteUser } from '@/api';
import { ElMessage } from 'element-plus';
export default {
    name:"account-settings",
    components:{
        ErrorDialog,
    },
    data(){
        return {
            dialogData:{
                errorMessage:"此操作进行后将无法恢复!是否确认继续!",
                isModalVisible:false,
                confirmEvent:"",
            },
            infoData:{
                mobile:null,
                email:null
            },
        }
    },
    methods:{
        // 修改操作
        handleBtnEvent(event){
            this.$store.commit("setEditItem",event)
            this.$router.push("/personal/account/reset")
        },
        // 注销按钮
        closeAccount(){
            this.dialogData.isModalVisible = true
        },
        // 确认注销
        confirm(){
            const user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
            deleteUser(user_id).then(response => {
                if(response.status == "200"){
                    ElMessage({
                        message:"注销成功",
                        type:"info"
                    })
                    localStorage.removeItem("userInfo")
                    location.reload()
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getUserInfo(){
            const userInfo = JSON.parse(localStorage.getItem("userInfo"))
            this.infoData.mobile = userInfo.mobile
            if(this.infoData.mobile){
                this.infoData.mobile = this.infoData.mobile.slice(0, 3) + "****" + this.infoData.mobile.slice(7);
            }
            this.infoData.email = userInfo.email
        },
    },
    mounted(){
        this.getUserInfo()
    },
    computed:{
        showResetText(){
            return this.$route.path == "/personal/account/settings"
        }
    },
}

</script>

<style>
.account-settings{
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-left: 20px;
    max-width: 1600px;
}
.account-settings-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid rgb(206, 202, 202);
    align-items: center;
    
}
.fixed-span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  white-space: nowrap; /* 禁止换行 */
}
.operate-btn{
    color: blue;
    cursor: pointer;
    margin-left: 30px;
}
.account-settings span{
    font-size: 15px;
}

</style>