<template>
    <div>
        <top-navigator ref="child">top bar</top-navigator>
        <div class="help_container">
            <div class="help_left">
                <div 
                    class="help_item" 
                    :class="{ active: selectedMenu === 'introduce' }"
                    @click="selectMenu('introduce')">
                    <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px"><Document /></el-icon>
                    <span class="help_text">简介</span>
                </div>
                <div 
                    class="help_item"
                    :class="{ active: selectedMenu === 'quicliyBegin' }"
                    @click="selectMenu('quicliyBegin')">
                    <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px"><DArrowRight /></el-icon>
                    <span class="help_text">快速开始</span>
                </div>
                <div 
                    class="help_item" 
                    :class="{ active: selectedMenu === 'viedeoStudy' }"
                    @click="selectMenu('viedeoStudy')">
                    <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px"><VideoPlay /></el-icon>
                    <span class="help_text">视频教程</span>
                </div>
            </div>
            <div class="help_right" v-if="selectedMenu == 'introduce'">
                <h1>简介</h1>
            </div>
            <div class="help_right" v-if="selectedMenu == 'quicliyBegin'">
                <h1>快速开始</h1>
            </div>
            <div class="help_right" v-if="selectedMenu == 'viedeoStudy'">
                <h1>视频教程</h1>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { ElIcon } from "element-plus";
  import TopNavigator from "@/components/TopBar.vue";
  import { VideoPlay,Document,DArrowRight } from "@element-plus/icons";
  export default {
      name: "HelpComponent",
      data() {
        return {
            selectedMenu:"introduce"
        }
      },
      components:{
        TopNavigator,
        ElIcon,
        VideoPlay,
        Document,
        DArrowRight
      },
      methods:{
        selectMenu(menu){
            this.selectedMenu = menu
        }
      }
  };
  </script>
<style>
.help_container{
    display: flex
}
.help_left{
    background-color: #f6f8fa;
    height: 94vh;
    width: 8%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.help_item{
    padding: 20px;
    display: flex;
    text-align: left;
}
.help_item.active {
    background-color: #e0e0e0;
}
.help_item:hover{
    background-color: #f0f0f0;
    cursor: pointer;
}
.help_right{
    margin-left: 10px
}
</style>