<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
    <div>
      <top-navigator>top bar</top-navigator>
  
      <div style="height:50px;display: flex;justify-content: left;margin-left: 100px;">
        <p class="get-inspired-title">全部生成图</p>
      </div>
      <div style="margin-top: 20px;justify-content: left;display: flex;margin-left: 100px;">
        <p>点击一个商品开始预览</p>
      </div>
      <div class="pcituresContainer">
        <div class="search-pcitures-container">
            <el-input 
                class="my-search-input-style"
                placeholder="请输入关键字"
                v-model="searchData"
                clearable
                >
                <template #suffix>
                    <el-icon style="height: 15px;width: 15px;margin-bottom: 10px;" @click="searchPictures"><Search /></el-icon>
                </template>
            </el-input>
        </div>
        <div class="gird-container">
          <div 
              v-for="picture in picturesList" 
              :key="picture.picture_id" 
              class="picture-container"
              @click="goToPreview(picture)">
              <img :src="picture.url"/>
              <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + picture.picture_id" @click="deletePicture(picture.picture_id)"><Delete/></el-icon>
              <el-icon style="top: 8px; right: 43px;" class="icon" @click="downloadFile(picture.picture_id,picture.url)"><Download/></el-icon>
              <i style="right: 3%; top: 85%;">
                <button class="preview-generate-button">查看</button>
              </i>
          </div>
        </div>
      </div>
      <div class="pagination_container"> 
        <ElConfigProvider :locale="zhCn">
          <el-pagination
            :page-sizes="[10, 20, 30, 50, 100]"
            layout="total,prev,pager,next,sizes,jumper"
            :total="totalPictures"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :size="pageData.pagesize"
          />
        </ElConfigProvider>
      </div>
    </div>
  </template>
  <script>
  import TopNavigator from "@/components/TopBar.vue";
  import { ElMessage,ElPagination,ElMessageBox,ElConfigProvider,ElInput} from "element-plus";
  import { getPictures,deletePicture } from "@/api/index";
  import { Download ,Delete,Search} from "@element-plus/icons";
  import zhCn from "element-plus/es/locale/lang/zh-cn";//！！！！！！！
  import { HttpCodes } from "@/common/statusCodes";
  export default {
    name: "assets-page",
    components: {
      TopNavigator,
      ElPagination,
      Download,
      Delete,
      ElConfigProvider,
      ElInput,
      Search,
    },
    data() {
      return{
        dialogData:{
          isModalVisible:false,
          errorImage:require("../assets/images/logo/error.jpg"),
          errorMessage:""
        },
        zhCn,
        modalVisible:null,
        searchData:"",
        picturesList:[],
        totalPictures:null,
        hovered: null,
        pageData:{
          pagesize:10,
          page:1
        },
        name:"",
        selectAsset:"",
  
      }
    },
    computed:{
    },
    methods: {
        confirm(){
            this.dialogData.isModalVisible = false
        },
        searchPictures() {
            if (this.searchData.trim() !== "") {
              console.log("search")
            } else {
              this.showMessage("请先输入关键字",'warning')
            }
        },
        showMessage(message, type) {
            ElMessage({
                message: message,
                type: type,
                plain: true,
            });
        },
        getPicturesList(){
            getPictures({ 
                page:this.pageData.page,
                page_size:this.pageData.pagesize
            }).then(response => {
                if(response.status == HttpCodes.SUCCESS){
                    this.totalPictures = response.data.data.count
                    console.log(this.totalPictures)
                    this.picturesList = response.data.data.pictures
                }
            }).catch(error => {
                console.error('There was an error!', error);
            })
        },
        // 改变分页大小
        handleSizeChange (page_size){
            this.pageData.pagesize = page_size
            this.getPicturesList()
        },
        // 变换页码
        handleCurrentChange(page){
            this.pageData.page = page
            this.getPicturesList()
        },
        deletePicture(picture_id){
          event.stopPropagation()
            ElMessageBox.confirm(
                '删除之后，将无法恢复，是否确认删除？',
                'Warning',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    lockScroll:false,
                }
            ).then(() => {
                deletePicture(picture_id)
                    .then(response => {
                    if(response.status == HttpCodes.SUCCESS){
                        this.picturesList = this.picturesList.filter(item => item.picture_id !== picture_id);
                        this.showMessage("删除成功","success")
                    }
                    })
                    .catch(error => {
                            console.error('Error deleting image:', error);
                        });
                    })
                .catch(() => {
                    this.showMessage("以取消删除","info")
            })
        },
        // 下载AI图
        downloadFile(id,url){
            event.stopPropagation()
            const fileName = id + ".jpg"
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        showPopup(itemId) {
            // 点击展示弹出框的方法
            if (this.modalVisible === itemId) {
                this.modalVisible = null; // 如果当前点击的和之前的相同，则关闭弹出框
            } else {
                this.modalVisible = itemId; // 否则打开对应的弹出框
            }
        },
        hidePopup() {
            this.modalVisible = null;
        },
        // 打开预览
        goToPreview(picture){
          this.$store.commit("setSelectPicture", picture)
          this.$router.push("/picturepreview")
        }
      
    },
    mounted(){
      this.getPicturesList()
    }
  };
  </script>
  
  <style>

.pcituresContainer {
  margin-left: 100px;
  margin-right: 100px;
  background-color: #fff;
  border-radius: 10px;
}
.gird-container{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr); 
    justify-content: start;
    margin-top: 20px;
}


.picture-container{
  width: 100%;
  aspect-ratio: 1 / 1;
  line-height: 1;
  font-size: 0;
  position: relative;
  margin: 5px 5px;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 20%)
}
.picture-container img {
  width: 100%;
  height: auto;

}

.picture-container:hover {
  cursor: pointer;
}

.picture-container:hover::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 99;
}

.picture-container:hover .icon {
  display: block;
  z-index: 999;
}

.picture-container i {
  display: none;
}

.picture-container:hover i {
  display: inline-block;
  position: absolute;
  font-size: 40px;
  z-index: 999;
  color: #fff;
  top: 50%;
}
.search-pcitures-container{
  display: flex;
  justify-content: left;
}
.my-search-input-style{
  width: 500px !important;
}
.my-search-input-style .el-input__wrapper .el-input__suffix:hover{
  cursor: pointer;
}
  </style>