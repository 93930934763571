<template>
    <div>
        <div class="orderDetails">
            <div style="display: flex;align-items: center;">
                <div style="height: 20px;width: 5px;background: blue;margin-left: 20px;border-radius: 5px"></div>
                    <span style="padding: 20px 20px 20px 10px;font-size: large;">订单概况</span>
                </div>
                <div class="order-info">
                    <div style="flex: 1;padding: 20px">
                        <div class="order-item">
                            <span  class="order-text">订单编号：</span>
                            <span class="order-text" style="margin-left: 10px">{{ order_info.order_sn }}</span>
                        </div>
                    <div class="order-item">
                        <span class="order-text">订单状态：</span>
                        <span 
                             v-if="order_info.pay_status === 'TRADE_SUCCESS' || order_info.pay_status === 'SUCCESS'"
                            class="order-text" 
                            style="margin-left: 10px;color: green;">
                                已支付
                        </span>
                        <span v-else
                            class="order-text" 
                            style="margin-left: 10px;color: red;">
                            未支付
                        </span>
                    </div>
                        <div class="order-item">
                            <span class="order-text">订单类型：</span>
                            <span  class="order-text" style="margin-left: 10px">{{ order_info.description }}</span>
                        </div>
                        <div class="order-item">
                            <span class="order-text">订单金额：</span>
                            <span class="order-text" style="margin-left: 10px">￥{{ order_info.order_amount }}</span>
                        </div>
                    </div>
                    <div style="flex: 1;padding: 20px">
                        <div class="order-item">
                            <span class="order-text">订单创建时间：</span>
                            <span class="order-text" style="margin-left: 10px">{{ order_info.created_at }}</span>
                        </div>
                        <div class="order-item">
                            <span class="order-text">订单支付时间：</span>
                            <span class="order-text" style="margin-left: 10px">{{ order_info.pay_time }}</span>
                        </div>
                        <div class="order-item">
                            <span class="cau order-text">产品名称：</span>
                            <span class="order-text" style="margin-left: 10px">{{ getSubscriptionLabel(order_info.subscribe_type) }}</span>
                        </div>
                        <div class="order-item">
                            <span class="cau order-text">计费方式：</span>
                            <span class="order-text" style="margin-left: 10px">{{ order_info.cau_method }}</span>
                        </div>
                    </div>
                </div>
            </div>
        <div/>
    </div>
</template>

<script>
import { ORDER_DESCRIPTION } from '@/common/statusCodes';
export default {
    name: "order-detail-page",
    components:{

    },
    data() {
        return {
            order_info:{},
            begin_time:"",

        };
    },
    methods:{
        // 获取订单计费方式
        getCauMethod(){
            // 判断是否有需要查看详情的订单
            if(Object.keys(this.$store.state.user.selectOrder).length === 0){
                this.$router.go(-1)
                return
            }
            this.order_info = this.$store.state.user.selectOrder
            if(["MONTHLY","MONTHLY_PRO"].includes(this.order_info.subscribe_type)){
                this.order_info.cau_method = "月费"
            }else if(['SEASON','SEASON_PRO'].includes(this.order_info.subscribe_type)){
                this.order_info.cau_method = "季费"
            }else{
                this.order_info.cau_method = "年费"
            }
            // 获取订单描述
            this.order_info.description = ORDER_DESCRIPTION[this.order_info.subscribe_type]
        },
        getSubscriptionLabel(type) {
            const labels = {
                MONTHLY: 'Basic月费',
                MONTHLY_PRO: 'Pro月费',
                SEASON: 'Basic季费',
                SEASON_PRO: 'Pro季费',
                YEARLY: 'Basic年费',
                YEARLY_PRO: 'Pro年费',
            };
            return labels[type] || '会员充值';
        },

    },
    mounted(){
        this.getCauMethod()

    }
};
</script>

<style>
html, body, #app {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
}


.orderDetails{
    max-width: 1600px;
    width: 85vw;
    display: flex;
    height: 350px;
    background: #ededef;
    flex-direction: column;
}
.order-info{
    display: flex;
    margin: 0px 20px 20px 20px;
    background: white;
    height: 250px
}
.order-item {
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    justify-content: left;
    align-items: center;
}
.cau::before{
    content: "   ";
    display: inline-block;
    margin-left: 32px; /* 可以根据需要调整空格的宽度 */
}
.order-text{
    color: #898a8c;
}
</style>