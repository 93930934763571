// Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved
export default {
    state: {
        // 用户信息,后续也可以存放token
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || 
        {
            isMember: false
        },
        // 用户绘图配置
        drawsettings: JSON.parse(localStorage.getItem("drawsettings")) || 
        {
            canvas_size: "1024x1024",
            images_num: 4,
        },
        // 保存用户所选绘画主题
        selectTheme:JSON.parse(localStorage.getItem("selectTheme")) || {},
        // 选择的商品图，使用localStorage来实现持久化存储
        selectAsset:JSON.parse(localStorage.getItem("selectAsset")) || {},

        // 选择的AI图
        selectPicture: JSON.parse(localStorage.getItem('selectPicture')) || {},

        assetKeyword:"",

        // 要查看详情的订单
        selectOrder:{},

        // 修改哪一样信息
        editItem:"password",
        // 存储文本内容
        textContent: JSON.parse(localStorage.getItem('textContent')) || {},




    },
    
    mutations: {
        // 登录成功被调用,登录过后设置用户信息
        setUserInfo(state,userInfo){
            state.userInfo = userInfo;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        },
        // 登录成功被调用
        setDrawsettings(state, drawsettings) {
            state.drawsettings = drawsettings;
            localStorage.setItem("drawsettings", JSON.stringify(drawsettings));
        },
        // 设置绘画主题
        setSelectTheme(state,theme){
            state.selectTheme = theme
            localStorage.setItem("selectTheme",JSON.stringify(theme))
        },
        setSelectAsset(state,asset){
            state.selectAsset = asset
            localStorage.setItem("selectAsset",JSON.stringify(asset))
        },
        // 设置选择的AiI图进行预览
        setSelectPicture(state,picture){
            state.selectPicture = picture
            localStorage.setItem('selectPicture',JSON.stringify(picture))
        },
        // 顶部查找asset关键字
        setAssetKeyword(state,data){
            state.assetKeyword = data
        },

        setSelectOrder(state,data){
            state.selectOrder = data
        },
        // 设置修改哪一项
        setEditItem(state,data){
            state.editItem = data
        },
        // 设置字体内容，跨组件间的数据共享
        setTextContent(state,data){
            state.textContent = data
            if(localStorage.getItem("textContent")){
                localStorage.removeItem('textContent')
            }
            localStorage.setItem('textContent',JSON.stringify(data))
        }


    },

    getters: {
        userInfo: state => state.userInfo,
        is_member: state => state.userInfo.is_member,
        token:state => state.userInfo.token,
        canvas_size: state => state.drawsettings.canvas_size,
        images_num: state => state.drawsettings.images_num
    }
  
}