<template>
    <div id="app">
<!--        <p>-->
<!--            &lt;!&ndash;使用 router-link 组件进行导航 &ndash;&gt;-->
<!--            &lt;!&ndash;通过传递 `to` 来指定链接 &ndash;&gt;-->
<!--            &lt;!&ndash;`<router-link>` 将呈现一个带有正确 `href` 属性的 `<a>` 标签&ndash;&gt;-->
<!--            <router-link to='/home'>-->
<!--                <home-page>首页</home-page>-->
<!--            </router-link>-->
<!--            <router-link to="/upload-image">-->
<!--                <upload-image>上传商品图</upload-image>-->
<!--            </router-link>-->
<!--            <router-link to='/pricing'>-->
<!--                <pricing>充值</pricing>-->
<!--            </router-link>-->
<!--            <router-link to='/about'>-->
<!--                <about>关于</about>-->
<!--            </router-link>-->
<!--            <router-link to='/contact'>-->
<!--                <contact>联系</contact>-->
<!--            </router-link>-->
<!--        </p>-->
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
    </div>
</template>

<script>

// import HomePage from "@/components/Home.vue";
// import UploadImage from "@/components/UploadProductImages.vue";
// import Pricing from "@/components/Pricing.vue";
// import Contact from "@/components/Contact.vue";
// import About from "@/components/About.vue";

export default {
    name: 'App',
    // components: {
    //     HomePage,
    //     UploadImage,
    //     Pricing,
    //     Contact,
    //     About,
    // }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 30px;
}
/* 有滚动条页面和没滚动条页面切换时，由于滚动条宽度会导致页面抖动 */
html {
  overflow-y: scroll; 
}
</style>
