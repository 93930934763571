<template>
    <div style="display: flex;padding: 70px;user-select: none;">
        <div
            id="themeContainer"
            ref="themeContainer"
            @wheel.prevent="onZoom"
            :style="{ backgroundImage: `url(${themeUrl})` }"
        >
            <div @mousedown="startDrag" ref="photo" id="photo">
                <img
                    :src="transparent_background_url"
                    :style="{
                        left: photoPosition.left + 'px',
                        top: photoPosition.top + 'px',
                        width: photoSize.width + 'px',
                        height: photoSize.height + 'px',
                        position: 'absolute',
                        objectFit: 'contain',
                    }"
                />
            </div>
        </div>
        <div class="btn-container">
            <div style="display: flex;;position: absolute;top: 20px;right: 20px;">
                <el-icon style="height: 50px;width: 50px;" @click="cancelUpdatePicture"><Back /></el-icon>
            </div>
            <div style="display: flex;;position: absolute;bottom: 75px;left: 900px;">
                <span 
                    :aria-disabled="isUpdating"
                    :class="{ 'disabled': isUpdating }"
                    @click="updatePictue"
                    >确认
                </span>
                <span style="background-color: darkgray;" @click="undoEditPicture">撤销</span>
            </div>
        </div>
    </div>
  </template>
  
<script>
import { ElLoading,ElMessage } from 'element-plus';
import { updataPicture } from '@/api';
import { HttpCodes } from '@/common/statusCodes';
import { Back } from '@element-plus/icons';
  export default {
    components:{
        Back,
    },
    data() {
      return {
        isUpdating:false,
        picture_id:null,
        // 变换状态
        transform: {
            scale_w:null,
            scale_h:null,
            x:null,
            y:null

        },
        // 背景图相对于1024 * 1024比例
        percentage: null,
        // 透明背景图
        transparent_background_url: require("../assets/images/samples/transparent_img.png"),
        // 是否正在拖动
        isDragging: false, 
        // 鼠标与图片的水平偏移量
        offsetX: 0,
        // 鼠标与图片的垂直偏移量
        offsetY: 0, 
        // 透明图位置
        photoPosition: {
          left: 0,
          top: 0,
        },
        // 透明图大小
        photoSize: {
          width: null,
          height: null,
        },
        // 当前缩放比例
        scale: 1,
        // 记录历史位置
        history: [],
        // 返回上次位置的下标
        undoIndex: -1,
        // 背景图theme_url
        themeUrl: require("../assets/images/samples/theme.jpg"),
        // 记录分辨率，记录实际缩放比例
        assetInitData:{
            width:null,
            height:null
        },
      };
    },
    mounted() {
        // 在vuex中获取要编辑的Picture
        this.getTransformData()
        // 监听键盘Ctrl + z事件，执行撤销
        window.addEventListener("keydown", this.handleUndo);
        // 根据transform的位置和缩放信息，确定照片在屏幕背景图中的位置
        this.getAssetInitData();
    },
    methods: {
        // 获取更新的Picture的位置缩放信息
        getTransformData(){
            if(Object.keys(this.$store.state.user.selectPicture).length === 0){
                this.$router.go(-1)
            }
            this.picture_id = this.$store.state.user.selectPicture.picture_id
            this.transform = JSON.parse(localStorage.getItem("selectPicture")).transform
            this.transparent_background_url = this.$store.state.user.selectPicture.transparent_background_url
            this.themeUrl = this.$store.state.user.selectPicture.theme_url
        },
        // 获取照片分辨率
        getAssetInitData() {
            // 获取照片元素
            const img = this.$refs.photo.querySelector("img");
            // 照片完成加载
            img.onload = () => {
                // 记录照片的分辨率
                this.assetInitData.width = img.naturalWidth
                this.assetInitData.height = img.naturalHeight
        
                // 获取themeContainer的高和宽
                const themeContainerHight = this.$refs.themeContainer.getBoundingClientRect().height;
                // 获取1024和屏幕容器比例
                const bl = 1024 / themeContainerHight;
                this.percentage = bl;
                // 将在1024 * 1024中的比例，转换为屏幕上背景图【theme_url】区域的比例
                this.transform.scale_w = this.transform.scale_w / bl
                this.transform.scale_h = this.transform.scale_h / bl

                // 获取在容器中的相对大小等比例
                this.photoPosition.left = this.transform.x / this.percentage;
                this.photoPosition.top = this.transform.y / this.percentage;
                // 获取图片的原始大小并缩放
                this.photoSize.width = img.naturalWidth * this.transform.scale_w;
                this.photoSize.height = img.naturalHeight * this.transform.scale_h;
                // 记录初始的位置缩放状态
                this.savePosition();
            };
        },
        // 保存变换记录，ctrl + 回到上次位置
        savePosition() {
            // 创建一个状态对象，保存当前图片的位置和大小
            const state = {
                // 深拷贝当前图片的位置
                position: { ...this.photoPosition },
                // 深拷贝当前图片的大小
                size: { ...this.photoSize }, 
            };
            // 将保存的状态推入历史记录数组中
            this.history.push(state);
            // 将撤销索引设置为最新的历史记录位置
            this.undoIndex = this.history.length - 1;
        },

        // 撤销操作 ctrl + z
        handleUndo(event) {
            // 检查用户是否按下了 ctrl 键和 "z" 键
            if (event.ctrlKey && event.key.toLowerCase() === "z") {
                // 确保撤销索引大于 0，防止越界操作
                if (this.undoIndex > 0) {
                    // 撤销索引减 1，回到上一个保存的状态
                    this.undoIndex--;
                    // 从历史记录中获取上一个状态
                    const lastState = this.history[this.undoIndex];
                    // 恢复图片的位置和大小
                    this.photoPosition = { ...lastState.position }; // 恢复位置
                    this.photoSize = { ...lastState.size };         // 恢复大小
                    // 恢复上次transform的信息
                    this.transform.x = this.photoPosition.left * this.percentage
                    this.transform.y = this.photoPosition.top * this.percentage
                    this.transform.scale_w = this.photoSize.width / this.assetInitData.width
                    this.transform.scale_h = this.photoSize.height / this.assetInitData.height
                }
            }
        },
        startDrag(event) {
            event.preventDefault();
            this.isDragging = true;
            this.offsetX = event.clientX - this.photoPosition.left;
            this.offsetY = event.clientY - this.photoPosition.top;
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.endDrag);
        },
        // 拖动
        onDrag(event) {
            if (this.isDragging) {
                let newLeft = event.clientX - this.offsetX;
                let newTop = event.clientY - this.offsetY;
        
                // 边界检查，防止超出容器
                const themeContainerWidth = this.$refs.themeContainer.offsetWidth;
                const themeContainerHeight = this.$refs.themeContainer.offsetHeight;
                const photoWidth = this.photoSize.width;
                const photoHeight = this.photoSize.height;
                // 确保不会超出边界
                newLeft = Math.max(0, Math.min(themeContainerWidth - photoWidth, newLeft));
                newTop = Math.max(0, Math.min(themeContainerHeight - photoHeight, newTop));
                // 记录此次相对于1024 * 1024的位置
                this.transform.x = this.percentage * newLeft
                this.transform.y = this.percentage * newTop
                // 修改图片位置
                this.photoPosition = { left: newLeft, top: newTop };
            }
        },
        // 停止拖动
        endDrag() {
            if (this.isDragging) {
                // 结束拖动
                this.isDragging = false;
                // 拖动结束后保存位置
                this.savePosition();
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.endDrag);
            }
        },
        // 缩放
        onZoom(event) {
            event.preventDefault();
            // 缩放因子，每次缩放的大小0.1
            const zoomFactor = event.deltaY > 0 ? 0.9 : 1.1; 
            let newScale = this.scale * zoomFactor;

            // 获取父容器尺寸
            const themeContainerWidth = this.$refs.themeContainer.offsetWidth;
            const themeContainerHeight = this.$refs.themeContainer.offsetHeight;

            // 计算缩放后的图片宽高
            let newWidth = this.photoSize.width * zoomFactor;
            let newHeight = this.photoSize.height * zoomFactor;

            // 检查是否超过父容器尺寸
            if (newWidth > themeContainerWidth || newHeight > themeContainerHeight) {
                // 按比例限制最大宽度和高度
                if (newWidth > themeContainerWidth) {
                    newWidth = themeContainerWidth;
                    newHeight = newWidth * (this.photoSize.height / this.photoSize.width);
                }
                
                if (newHeight > themeContainerHeight) {
                    newHeight = themeContainerHeight;
                    newWidth = newHeight * (this.photoSize.width / this.photoSize.height);
                }
                
                // 重置缩放比例以防止进一步缩放
                newScale = newWidth / this.photoSize.width;
            }

            // 计算当前中心点，以照片中心进行缩放
            const centerX = this.photoPosition.left + (this.photoSize.width / 2);
            const centerY = this.photoPosition.top + (this.photoSize.height / 2);

            // 更新图片尺寸
            this.photoSize.width = newWidth;
            this.photoSize.height = newHeight;

            // 更新图片位置，使其以中心点缩放
            this.photoPosition.left = centerX - (newWidth / 2);
            this.photoPosition.top = centerY - (newHeight / 2);
            if(this.photoPosition.left < 0){
                this.photoPosition.left = 0
            }
            if(this.photoPosition.top < 0){
                this.photoPosition.top = 0
            }

            this.scale = newScale; // 更新缩放比例
            // 计算变化新的transform
            this.transform.x = this.photoPosition.left * this.percentage
            this.transform.y = this.photoPosition.top * this.percentage
            this.transform.scale_w = newWidth / this.assetInitData.width
            this.transform.scale_h = newHeight / this.assetInitData.height
            this.savePosition(); // 缩放后保存位置和尺寸
        },
        // 返回
        cancelUpdatePicture(){
            this.$router.go(-1)
        },
        updatePictue(){
            this.isUpdating = true
            // TODO:显示全屏加载中会影响Asset的位置
            const loading = ElLoading.service({
                lock: true,
                text: '正在更新中...',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const timeout = 60000;
            const updateTimeoutId = setTimeout(() => {
                loading.close();
                ElMessage({
                    message:"请求超时请重试",
                    type:"info",
                })
            }, timeout);
            // 照片转换为在1024 * 1024中的大小比例
            this.transform.scale_w = this.transform.scale_w * this.percentage
            this.transform.scale_h = this.transform.scale_h * this.percentage
            updataPicture(this.picture_id,{transform:this.transform}).then(response => {
                if(response.status == HttpCodes.SUCCESS){
                    this.isUpdating = false
                    clearTimeout(updateTimeoutId)
                    loading.close()
                    // 还原到屏幕背景中 800 * 800
                    this.transform.scale_w = this.transform.scale_w / this.percentage
                    this.transform.scale_h = this.transform.scale_h / this.percentage
                    // 更新预览模式下的picture的url
                    const selectPicture = this.$store.state.user.selectPicture
                    selectPicture.url = response.data.data.new_url
                    this.$store.commit("setSelectPicture", selectPicture)
                    ElMessage({
                        message:"更新成功",
                        type:"success"
                    })
                }
            }).catch(error => {
                console.log("Update picture error:",error)
                // 还原到屏幕背景中 800 * 800
                this.transform.scale_w = this.transform.scale_w / this.percentage
                this.transform.scale_h = this.transform.scale_h / this.percentage
                this.isUpdating = false
                loading.close()
                clearTimeout(updateTimeoutId)
            })
        },
        // 撤销编辑回到最初位置
        undoEditPicture(){
            // 获取第一个初始位置的值
            const lastState = this.history[0];
            // 改变位置
            this.photoPosition = { ...lastState.position }; // 恢复位置
            // 改变大小
            this.photoSize = { ...lastState.size };  
        }
    },
};
</script>
  
<style scoped>

#themeContainer {
    width: 800px;
    height: 800px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
  
#photo {
    cursor: grab;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
}
.btn-container div:hover{
    cursor: pointer;
}
.btn-container div span{
    padding: 15px 40px;
    background-color: #409eff;
    color: white;
    border-radius: 10px;
    margin-left: 15px;
    white-space: nowrap;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
  </style>
  