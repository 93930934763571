<template>
    <transition name="modal-fade">
        <div v-if="visible" class="modal-overlay">
            <div class="modal-content" @click.stop>
                <slot></slot>
                <el-icon class="modal-close" @click="closeModal"><Close /></el-icon>
                <div class="dialog-bottom">
                    <div class="dialog-bottom-btn close-background" @click="closeModal"><span>取消</span></div>
                    <div class="dialog-bottom-btn confirm-background" @click="confirm"><span>确认</span></div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { Close } from '@element-plus/icons';
export default {
  components:{
    Close,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    confirm(){
      this.$emit('confirm');
    }
  },
  computed: {
    visible() {
      return this.modelValue;
    }
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  /* height: 350px; */
  width: 500px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

/* 过渡动画 */
.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* 弹出时的初始状态 */
.modal-fade-enter {
  opacity: 0;
  transform: scale(0.8);
}

/* 关闭时的最终状态 */
.modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.modal-close {
  background: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  height: 25px;
  width: 25px;
}
.dialog-bottom{
    display: flex;
    justify-content: space-between;; 
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    align-items: center;

  }

  .dialog-bottom-btn{
    padding: 10px 60px;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }
  .dialog-bottom-btn:hover{
    cursor: pointer;
    transform: translateY(-5px); 
    box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.3); 
  }
  .dialog-bottom-btn span{
    color: white;
  }
  .close-background{
    margin-left: 20px;
    background: rgb(75, 73, 73);
  }
  .confirm-background{
    margin-right: 20px;
    background: rgb(22, 167, 22);
  }
</style>
