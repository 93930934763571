<template>
    <div id="app">
        <top-navigator>top bar</top-navigator>
        <div class="infoContainer">
            <PersonalNavigator></PersonalNavigator>
            <div class="infoRight">
                <div class="rightTop">
                    <div class="bread">
                        <span class="bread-span bread-span-weight" @click="goToAccountSettings">账号设置</span>
                        <span class="bread-span" v-if="showResetForm">{{ resetItem }}</span>
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import TopNavigator from "@/components/TopBar.vue";
import PersonalNavigator from './templates/PersonalNavigator.vue';
export default {
    name:"profile-settings",
    components:{
        TopNavigator,
        PersonalNavigator
    },
    data(){
        return {
        }
    },
    methods:{
        goToAccountSettings(){
            this.$router.push("/personal/account/settings")
        }
    },
    computed:{
        showResetForm(){
            return this.$route.path == "/personal/account/reset"
        },
        // 动态获取当前路由
        currentRoutePath(){
            return this.$route.path
        },
        // 获取要修改哪一项
        resetItem() {
            const resetItem = this.$store.state.user.editItem;
            const resetMap = {
                password: "修改密码",
                email:"修改邮箱"
            };
            
            // 判断当前路径是否为重置页
            if (this.currentRoutePath === "/personal/account/reset") {
                return resetMap[resetItem] || ""; 
            }
            return "";
        }
    },
    mounted(){

    }
}

</script>

<style>


</style>