<template>
  <div class="draw-settings-container">
    <div style="height: 5%; display: flex; margin-top: 10px">
      <h3 style="margin: 0">设置</h3>
    </div>
    <div class="subscribe" v-if="showSubscribe">
      <span style="color: #a4a6a9">Subscribe to access these settings</span>
      <button
        :class="Membership ? 'sub-btn-select' : 'sub-btn'"
        @click="goToPricing"
      >
        {{ is_sub ? "已订阅" : "订阅" }}
      </button>
    </div>
    <div class="size">
      <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
        <span class="text">Canvas size</span>
      </div>
      <div
        class="item"
        :class="{ item: true, active: option === 0 , disabled: showSubscribe}"
        @click="selectItem(0)"
      >
        <span class="text">默认</span>
        <span class="text">1024×1024</span>
      </div>
      <div
        class="middle-item"
        :class="{ item: true, active: option === 1 , disabled: showSubscribe}"
        @click="selectItem(1)"
      >
        <span class="text">16:9</span>
      </div>
      <div
        class="item"
        :class="{ item: true, active: option === 2,disabled: showSubscribe }"
        @click="selectItem(2)"
      >
        <span class="text">9:16</span>
      </div>
      <div>
        <div style="display: flex; margin-top: 30px; margin-bottom: 40px">
          <span class="text">生成AI图数量</span>
        </div>
        <div class="block">
          <el-slider
            v-model="images_num"
            :step="1"
            :max="10"
            :disabled="!Membership"
            placement="bottom"
            :show-tooltip="false"
            @input="updateTooltip"
            style="width: 95%"
            ref="slider"
          >
          </el-slider>
          <!-- 自定义提示词 -->
          <div
            class="custom-tooltip"
            :style="{
              top: tooltipPosition.top + 'px',
              left: tooltipPosition.left + 'px',
            }"
          >
            {{ images_num }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Membership"
      style="
        display: flex;
        margin-top: 60px;
        justify-content: left;
        align-items: center;
      "
    >
      <!-- <el-button type="primary" @click="settings">保存修改</el-button> -->
      <button class="save-btn" @click="settings">保存修改</button>
    </div>
  </div>
</template>

<script>
import { ElSlider, ElMessage } from "element-plus";
import { updateDrawsettings } from "@/api";
import { HttpCodes } from "@/common/statusCodes";
export default {
  name: "navigator-settings",
  data() {
    return {
      option: 0, // 默认选中第一个item
      images_num: null,
      Membership: false,
      canvas_size: "",
      sizes: ["1024x1024", "1600x1600", "2056x2056"],
      id: "",
      showSubscribe: true,
      // 提示词的位置
      tooltipPosition: { top: 0, left: 0 },
    };
  },
  components: {
    ElSlider,
  },
  methods: {
    selectItem(index) {
      if (this.Membership) {
        this.option = index;
      } else {
        ElMessage({
          message: "订阅之后享受自定义配置",
          type: "info",
          plain: true,
        });
      }
    },
    goToPricing() {
      this.$router.push("/pricing");
    },
    settings() {
      this.canvas_size = this.sizes[this.option] || "未知大小";
      const formData = new FormData();
      formData.append("canvas_size", this.canvas_size);
      formData.append("images_num", this.images_num);
      this.id = JSON.parse(localStorage.getItem("drawsettings")).id;
      updateDrawsettings(this.id, formData)
        .then((response) => {
          // 如果是会员请求修改配置成功时更新localStorage中的绘画配置
          this.$store.commit("setDrawsettings", response.data.data);
          if (response.status == HttpCodes.SUCCESS) {
            ElMessage({
              message: "已成功为你修改绘图配置",
              type: "success",
              plain: true,
            });
          } else {
            ElMessage({
              message: "配置失败",
              type: "warning",
              plain: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSettings() {
      this.id = this.$store.state.user.drawsettings.id;
      this.canvas_size = this.$store.getters.canvas_size;
      this.images_num = this.$store.getters.images_num;
      const index = this.sizes.findIndex((size) => size === this.canvas_size);
      if (index !== -1) {
        this.option = index;
      } else {
        console.error("Canvas size not found in sizes array");
      }
    },
    // 更新提示词的位置，随着images_num的改变而改变
    updateTooltip() {
      const slider = this.$refs.slider.$el.querySelector(".el-slider__runway");
      const rect = slider.getBoundingClientRect();
      const percentage = (this.images_num - 0) / (10 - 0); // 根据最大值和最小值计算
      this.tooltipPosition.left = rect.left + rect.width * percentage - 20; // 调整位置
      this.tooltipPosition.top = rect.top + 20; // 调整高度
    },
  },
  // 清理事件监听器
  beforeUnmount() {
    window.removeEventListener("resize", this.updateTooltip);
  },
  mounted() {
    this.getSettings();
    this.Membership = this.$store.getters.is_member;
    this.showSubscribe = !this.Membership;
    this.$nextTick(() => {
      this.updateTooltip(); // 确保 DOM 更新后再调用
    });
    window.addEventListener("resize", this.updateTooltip); // 监听窗口变化
  },
};
</script>
<style scoped>
.draw-settings-container {
  width: 83%;
  height: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.subscribe {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e0e1e3;
}

.sub-btn {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  background-color: #f6de60;
  color: black;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.save-btn {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f6de60;
  color: black;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.sub-btn-select {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  background-color: #e0e1e3;
  color: black;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.sub-btn:hover {
  background-color: #eccf3e; /* 按钮悬停时背景颜色 */
}
.size {
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  border: 1px solid #e0e1e3;
  cursor: pointer;
}
/* 置灰样式 */
.disabled{
  background-color: #f6f7f9;
  cursor: not-allowed;
}
.middle-item {
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  border-left: 1px solid #e0e1e3;
  border-right: 1px solid #e0e1e3;
}
.text {
  font-size: large;
  color: #a4a6a9;
}
.active {
  background-color: #f6de60; /* 高亮背景色 */
}
.block {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.el-slider__bar) {
  background: #f6de60 !important;
}
:deep(.el-slider__button) {
  border: 2px solid #f6de60;
  border-color: #f6de60 !important;
}

:deep(.el-slider__tooltip) {
  display: block !important; /* 强制显示为 block */
}
.custom-tooltip {
  margin-top: 2px;
  height: 20px;
  aspect-ratio: 1 / 1;
  position: absolute;
  background-color: #f6de60;
  border-radius: 6px;
  padding: 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>