<template>
  <div style="width: 83%;max-height: 100%;display: flex;flex-direction: column;">
    <!-- <h3 style="margin-bottom: 10px">Asset</h3> -->
    <div class="top_container">
      <div class="upload_asset_btn" @click="toUploadAsset">
        <span style="font-size: large; font-weight: bold;">上传</span>
      </div>
    </div>
    <div class="search_asset_btn">
      <el-input
        v-model="searchName"
        style="width: 100%"
        placeholder="请输入商品关键字"
        :prefix-icon="Search">
        <template #prefix>
            <el-icon style="height: 20px;width: 20px;" @click="searchAssets">
              <Search/>
            </el-icon>
        </template>
      </el-input>
    </div>
    <div class="images_container">
        <div class="asset_container">
          <div class="photo" v-for="asset in assetsList" :key="asset.asset_id" @click="selectAsset(asset)" :class="{ 'selected': selectedAsset === asset.asset_id }">
            <img :src="asset.asset_url" alt="">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ElInput ,ElMessage} from 'element-plus';
import { Search } from '@element-plus/icons-vue'
import { getAssets } from '@/api';
import { HttpCodes } from '@/common/statusCodes';
export default {
    name: "navigator-assets",
    components:{
      ElInput,
      Search,
      
    },
    data() {
      return {
        searchName:"",
        assetsList:[],
        selectedAsset:""
      }
    },

    methods: {
      toUploadAsset(){
        this.$router.push("/upload")
      },
      searchAssets(){
        if(this.searchName != ""){
          this.getAssetsList()
           ElMessage({
            message:"以为你搜索出关键字为" + this.searchName + "的商品",
            type:"success"
          })
          this.searchName = ""
        }else{
          ElMessage({
            message:"请先输入关键字",
            type:"warning"
          })
        }
      },
      getAssetsList(){
        getAssets({
          name:this.searchName
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            this.assetsList = response.data.data.assets
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 更改要生成AI图的商品
      selectAsset(asset) {
        this.selectedAsset = asset.asset_id;
        this.$store.commit("setSelectAsset", asset)
        // 进入预览页面
        this.$router.push("/assetpreview")
      },
    },
    mounted(){
      this.getAssetsList()
    }
};
</script>
<style>
.top_container{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.el-input__prefix{
  height: 30px;
  width: 25px;
}
 .upload_asset_btn{
  background-color: #f6de60;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
 }
 .upload_asset_btn:hover{
  cursor: pointer;
  background-color: #f3d645;
 }
 .search_asset_btn{
  margin-top: 10px;
 }
 .images_container{
  height: 89%;
  width: 100%;
  margin-top: 10px;
  background-color: rgb(246, 248, 250); 
  border: 1px solid #ccc;
  overflow-y: auto;
  overflow-x: none;
}

 .asset_container {
    margin-top: 10px;
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
    width: 100%; 
    padding: 5px; 
    box-sizing: border-box; 
  }
  .photo{
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .photo img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
  }
  .photo:hover::after {
    cursor: pointer;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 500;
  }

</style>