// src/common/statusCodes.js
export const HttpCodes = Object.freeze({

    // 请求成功
    SUCCESS: 200,
    // 已成功创建
    CREATED: 201,
    // 删除成功
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
  });

  
export const StatusCodes = Object.freeze({
  SMS_CODE_THROTTLING_ERR: 4009,
  PRODUCT_EXCEED_PICTURES_MAXIMUM_ERR:7027
})

// 订单描述
export const ORDER_DESCRIPTION = {
  MONTHLY: 'Basic会员月费充值',
  SEASON: 'Basic会员季费充值',
  YEARLY: 'Basic会员年费充值',
  MONTHLY_PRO: 'Pro会员月费充值',
  SEASON_PRO: 'Pro会员季费充值',
  YEARLY_PRO: 'Pro会员年费充值'
};

  