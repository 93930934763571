<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <top-navigator>top bar</top-navigator>
    <div class='container'>
      <div style="width: 100vw; height: 200px;max-width: 1600px;">
        <div style="margin-top: 30px; margin-left: 50px; float: left">
          <p class="welcome-title">欢迎来到ProductPix!</p>
        </div>
        <div style="margin-top: 60px; float: right">
          <button class="upload-button" @click="jumpToOther('/upload')">
            上传商品
          </button>
        </div>
      </div>
      <!-- 获取灵感 -->
      <div>
        <div style="width: 100vw; height: 90px;max-width: 1600px;">
          <div>
            <div style="margin-top: 10px; margin-left: 50px; float: left">
              <p class="get-inspired-title">获取灵感</p>
            </div>
            <div style="margin-top: 15px; float: right">
              <button class="view-more-button">发现更多</button>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px; width: 100vw; height: 15px;max-width: 1600px;">
          <div style="margin-top: 3px; margin-left: 50px; float: left">
            <p>选择合适的场景，创建独属于你的商品图</p>
          </div>
        </div>
        <div style="margin-top: 50px; width: 100vw; height: 350px;max-width: 1600px;">
          <SampleFloatLayerVue></SampleFloatLayerVue>
        </div>
      </div>
      <!-- 个人商品库 -->
      <div>
        <div style="width: 100vw; height: 90px;max-width: 1600px;">
          <div>
            <div style="margin-top: 10px; margin-left: 50px; float: left">
              <p class="get-inspired-title">你的商品库</p>
            </div>
            <div style="margin-top: 15px; float: right">
              <button class="view-more-button" @click="jumpToOther('/assets')">
                查看全部
              </button>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px; width: 100vw; height: 15px;max-width: 1600px">
          <div style="margin-left: 50px; float: left">
            <p>点击一个商品开始创作</p>
          </div>
        </div>

        <div
          style="margin-top: 50px; width: 100vw; height: 350px; display: flex;max-width: 1600px;"
        >
          <ProductFloatLayer ref="assetChild"></ProductFloatLayer>
        </div>
      </div>
      <!-- AI图展示区 -->
      <div>
        <div style="width: 100vw; height: 90px; margin-top: 50px;max-width: 1600px;">
          <div>
            <div style="margin-top: 10px; margin-left: 50px; float: left">
              <p class="get-inspired-title">最近使用</p>
            </div>
            <div style="margin-top: 15px; float: right">
              <button
                class="view-more-button"
                @click="jumpToOther('/pictures')"
              >
                查看全部
              </button>
            </div>
          </div>
        </div>
        <div style="width: 100vw; height: 350px; display: flex;max-width: 1600px;">
          <PictureFloatLayer ref="PictureFloatLayer"></PictureFloatLayer>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import TopNavigator from "@/components/TopBar.vue";
import ProductFloatLayer from "@/components/templates/AssetFloatLayer.vue";
import PictureFloatLayer from "./templates/PictureFloatLayer.vue";
import SampleFloatLayerVue from './templates/SampleFloatLayer.vue';
export default {
  name: "home-page",
  components: {
    TopNavigator,
    ProductFloatLayer,
    PictureFloatLayer,
    SampleFloatLayerVue
  },

  data() {
    return {};
  },
  // 进入generate
  beforeRouteEnter(to, from, next) {
    // 如果是从 picture 编辑页面返回的，则进行 picture 刷新操作
    // 通过vm引用访问组件实例的 refs
    next((vm) => {
      if (from.path === "/editpicture") {
        vm.$refs.PictureFloatLayer.getPicturesList();
      }
    });
  },

  mounted() {},
  computed: {},
  methods: {
    // 跳转至其它页面
    jumpToOther(index) {
      this.$router.push(index);
    },
  },
};
</script>
  
  <style>
/* 居中显示 */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  user-select: none;
}
/* 隐藏container的滚动条 */
.hideContainer {
  overflow: auto;
  height: 90vh;
}
.welcome-title {
  font-size: 30px;
}

.secondary-title {
  font-size: 27px;
}

.get-inspired-title {
  font-size: 27px;
}

.sample-image {
  display: table;
  float: left;
  width: 25%;
  color: blue;
}

.sample-image img {
  margin: auto;
  width: 300px;
  height: 300px;
}

.view-more-button {
  width: 120px;
  height: 60px;
  border: None;
  font-size: 17px;
  font-weight: bolder;
  font-style: initial;
  background-color: white;
  color: black;
}
.view-more-button:hover {
  cursor: pointer;
}

.upload-button {
  width: 120px;
  height: 60px;
  border: None;
  font-size: 17px;
  font-weight: bolder;
  font-style: initial;
  background-color: orange;
  color: black;
  border-radius: 5px;
}
</style>
  
  
  