<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <div v-if="show_top" style="background-color: chocolate; height: 30px; width: 100%; display: flex; align-items: center; justify-content: center; position: relative;">
        <p style="margin: 0; font-weight: bold; font-size: small;">
            您的当月AI图余额已用完，成功会员即可畅享本工具！
            <span class="more" @click="goToRoutes('/pricing')">了解更多</span>
        </p>
        <el-icon class="close-icon" @click="clodeAd">
            <Close />
        </el-icon>
    </div>
    <div class="title">
        <div style="display: flex;justify-content: center;align-items: center;">
            <img src="../assets/mylogo.png" width="100" height="50" alt="logo">
            <div class="search_box custom-input-wrapper">
              <el-input 
                type="text" 
                class="floating-input custom-bg-input " 
                placeholder="请输入关键字" 
                v-model="searchQuery" 
                size="large" 
                clearable
                @keydown.enter="search"
                style="width: 100%; height: 40px;">
                <template #append>
                  <div class="custom-icon-container" @click="search">
                    <el-icon><Search /></el-icon>
                  </div>
                </template>
              </el-input>
            </div>
        </div>
        <div style="width: 40%;display: flex;justify-content: right;align-items: center;">
          <router-link 
              :to="loginStatus ? '/personal' : '/home'" 
              class="my-router-link" 
              :class="{ active: selectedView === 'personal' }"
              @click="selectView('personal')"
              >
              <el-icon style="height: 15px;width: 15px;margin-top: 2px;margin-right: 5px"><House /></el-icon>
              <span class="">首页</span>
          </router-link>
          <router-link 
              to="/pricing" 
              class="my-router-link"
              :class="{ active: selectedView === 'pricing' }"
              @click="selectView('pricing')"
              >
              <el-icon style="height: 15px;width: 15px;margin-top: 2px;margin-right: 5px"><PriceTag /></el-icon>
              <span class=""  @click="goToRoutes('/pricing')">充值</span>
          </router-link>
          <router-link 
              to="/help" 
              class="my-router-link"
              :class="{ active: selectedView === 'help' }"
              @click="selectView('help')"
              >
              <el-icon style="height: 15px;width: 15px;margin-top: 2px;margin-right: 5px"><Help /></el-icon>
              <span class="">帮助</span>
          </router-link>
          <router-link 
              to="/contact" 
              class="my-router-link"
              :class="{ active: selectedView === 'contact' }"
              @click="selectView('contact')">
              <el-icon style="height: 15px;width: 15px;margin-top: 2px;margin-right: 5px"><Phone /></el-icon>
              <span class="">联系</span>
          </router-link>
          <el-button type="info" plain  v-if="showLogin" @click="dialogVisible = true" class="login-btn-style">登录/注册</el-button>
          <el-dropdown  v-if="loginStatus" style="margin-left: 10px" class="dropdown-style">
              <img src="../assets/images/logo/avatar.png" alt="" style="height: 30px;width: 30px">
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <div style="display: flex;justify-content: center;align-items: center;" @click="goToRoutes('/personal')">
                      <img src="../assets/images/logo/avatar.png" alt="" style="height: 40px;width: 40px">
                      <span style="margin-left: 10px;font-weight: bold">这是我的名字</span>
                  </div>
                </el-dropdown-item>
                <!-- 订单导航 -->
                <el-dropdown-item>
                  <div class="dropdown-item-style" @click="goToRoutes('/personal/order')">
                    <el-icon style="height: 20px;width: 20px;margin-right: 15px"><Tickets /></el-icon>
                    <span>订单</span>
                  </div>
                </el-dropdown-item>
                <!-- 个人中心导航 -->
                <el-dropdown-item>
                  <div class="dropdown-item-style" @click="goToRoutes('/personal/center')">
                    <el-icon style="height: 20px;width: 20px;margin-right: 15px"><User /></el-icon>
                    <span>个人中心</span>
                  </div>
                </el-dropdown-item>
                <!-- 账号设置导航 -->
                <el-dropdown-item>
                  <div class="dropdown-item-style" @click="goToRoutes('/personal/account')">
                    <el-icon style="height: 20px;width: 20px;margin-right: 15px"><Setting /></el-icon>
                    <span>账号设置</span>
                  </div>
                </el-dropdown-item>
                <!-- 退出登录 -->
                <el-dropdown-item>
                  <div class="dropdown-item-style">
                    <i  style="font-size: 20px;margin-right: 20px" class="iconfont icon-tuichu"></i>
                    <span @click="exit">退出</span>
                  </div>
                </el-dropdown-item>
                </el-dropdown-menu>
            </template>
          </el-dropdown>
      </div>
    <el-dialog
      v-model="dialogVisible"
      width="800px"
      :show-close="false">
      <el-dialog
        v-model="innerVisible"
        width="500"
        append-to-body
        :show-close="false">
        <div class="dialog-container">
          <h1 style="margin: 5px 0 5px 120px;">{{ innerTitle }}</h1>
          <div class="file-content" v-if="fileContent">{{ fileContent }}</div>
          <div class="dialog-btn" @click="closeInnerDialog">
            <span>关闭</span>
          </div>
        </div>
       </el-dialog>
        <div>
          <div class="dialog-content">
            <div class="right-content">
              <div style="margin-bottom: 20px">
                <div class="switch-login-btn">
                    <h1 
                      :class="{ selected: activeTab === 'login' }" 
                      @click="selectTab('login')"
                      v-if="!isforgetPassword"
                    >
                      登录
                    </h1>
                    <h1 
                      :class="{ selected: activeTab === 'register' }" 
                      @click="selectTab('register')"
                      v-if="!isforgetPassword"
                    >
                      注册
                    </h1>
                    <h1 v-if="isforgetPassword">忘记密码</h1>
                  </div>
              </div>
              <div v-if="isLogin">
                <div style="margin-left: 50px;margin-right: 50px;margin-bottom: 20px;">
                <el-form :model="loginForm" :rules="rules" ref="loginForm">
                      <el-form-item prop="phone" style="margin-bottom: 20px;">
                        <el-input v-model="loginForm.phone" size="large" placeholder="请输入手机号">
                          <template #prepend>
                            <span>+86</span>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item v-if="!isPhoneLogin" style="margin-bottom: 0px;" prop="password">
                        <el-input v-model="loginForm.password" size="large" show-password placeholder="请输入密码密码">
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 0px;" v-if="isPhoneLogin" prop="verificationCode">
                        <el-input v-model="loginForm.verificationCode" size="large" placeholder="请输入验证码">
                          <template #append>
                            <el-button
                              size="large"
                              type="primary"
                              @click="getVerificationCode(1)"
                              :disabled="isLoginCountingDown"
                              :style="isVaild ? getCodeBtnStyle:''"
                              >
                              {{ isLoginCountingDown ? LoginCountdown + "秒" : '获取验证码' }}
                            </el-button>
                          </template>
                        </el-input>
                      </el-form-item>
                      <div style="display: flex;justify-content: left;">
                        <el-checkbox v-model="loginForm.agreement" @change="toggleSelection" style="margin-top: 20px;margin-bottom: 20px;">
                          <span style="font-size: small; margin-top: 0px;">
                            我已阅读并同意
                            <a @click.stop="openInner(0)" class="a-style">《服务协议》</a>
                            <span style="font-size: small;">和</span>
                            <a @click.stop="openInner(1)" class="a-style">《隐私政策》</a>
                          </span>
                        </el-checkbox>
                      </div>
                      <el-form-item style="width: 100%;">
                        <el-button type="primary" size="large" style="width: 100%;" @click="login" :disabled="!loginForm.agreement || isLoging" >登录</el-button>
                      </el-form-item> 
                </el-form>
              </div>
              <div class="other-logins">
                <div class="other-login" @click="wechat">
                  <img src="../assets/images/logo/wechart.png" alt="">
                  <span>微信登录</span>
                </div>
              </div>
              <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
                <div class="forget-password">
                  <span v-if="!isPhoneLogin" @click="phoneLogin">短信验证</span>
                  <span v-if="isPhoneLogin" @click="phoneLogin">密码登录</span>
                  <span v-if="!isPhoneLogin" @click="forgetPassowrd">忘记密码?</span>
                </div>
              </div>
              <div class="other">
                <div style="margin-top: 20px;margin-bottom: 10px;">
                  <span style="font-size: small;">关注官方账号，畅享最新咨询</span>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 20px; margin-bottom: 20px;">
                  <img src="../assets/images/logo/wechat-hb.png" alt="" style="height: 20px; width: 25px; margin: 0 15px;">
                  <img src="../assets/images/logo/red-book.png" alt="" style="height: 20px; width: 40px; margin: 0 15px;">
                  <img src="../assets/images/logo/zb.png" alt="" style="height: 20px; width: 25px; margin: 0 15px;">
                  <img src="../assets/images/logo/dy.png" alt="" style="height: 20px; width: 25px; margin: 0 15px;">
                </div>
              </div>
              </div>
              <div v-if="isRegister">
                <div style="margin-left: 50px;margin-right: 50px;margin-bottom: 20px;height: 460px">
                  <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
                      <el-form-item prop="phone" style="margin-bottom: 20px;">
                        <el-input v-model="registerForm.phone" size="large" placeholder="请输入手机号">
                          <template #prepend>
                            <span>+86</span>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 20px;" prop="verificationCode">
                        <el-input v-model="registerForm.verificationCode" size="large" placeholder="请输入验证码">
                          <template #append>
                            <el-button
                              size="large"
                              type="primary"
                              @click="getVerificationCode(2)"
                              :disabled="isRegisterCountingDown"
                              :style="isVaild ? getCodeBtnStyle:''"
                              >
                              {{ isRegisterCountingDown ? RegisterCountdown + "秒" : '获取验证码' }}
                            </el-button>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 20px" prop="password">
                        <el-input v-model="registerForm.password" size="large" placeholder="请输入8-16位包含数字和字母组合" show-password>
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 0px" prop="confirmPassword">
                        <el-input v-model="registerForm.confirmPassword" size="large" placeholder="请输入8-16位包含数字和字母组合" show-password>
                        </el-input>
                      </el-form-item>
                      <div style="display: flex;justify-content: center;align-items: center;">
                        <el-checkbox v-model="registerForm.agreement" @change="toggleSelection" style="margin-top: 20px;margin-bottom: 20px;">
                          <span style="font-size: small; margin-top: 0px;">
                            我已阅读并同意
                            <a @click.stop="openInner(0)" style="color: blue; text-decoration: none;" onmouseover="this.style.textDecoration='underline'" onmouseout="this.style.textDecoration='none'">《服务协议》</a>
                            <span style="font-size: small;">和</span>
                            <a @click.stop="openInner(1)" style="color: blue; text-decoration: none;" onmouseover="this.style.textDecoration='underline'" onmouseout="this.style.textDecoration='none'">《隐私政策》</a>
                          </span>
                        </el-checkbox>
                      </div>
                      <el-form-item style="width: 100%;">
                        <el-button type="primary" size="large" style="width: 100%;" @click="register" :disabled="!registerForm.agreement || isRegistering" >注册</el-button>
                      </el-form-item> 
                </el-form>
                <div class="register-info">
                  <p style="font-size: small;text-align: left;">已有账号，<span style="font-size: small;color: blue;" @click="toLogin">点击登录</span></p>
                </div>
                </div>
              </div>
              <div v-if="isforgetPassword">
                <div style="margin-left: 50px;margin-right: 50px;margin-bottom: 20px;height: 460px">
                  <el-form :model="resetPasswordForm" :rules="resetPsswordRules" ref="resetPasswordForm">
                      <el-form-item prop="phone" style="margin-bottom: 20px;">
                        <el-input v-model="resetPasswordForm.phone" size="large" placeholder="请输入手机号">
                          <template #prepend>
                            <span>+86</span>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 20px;" prop="verificationCode">
                        <el-input v-model="resetPasswordForm.verificationCode" size="large" placeholder="请输入验证码">
                          <template #append>
                            <el-button
                              size="large"
                              type="primary"
                              @click="getVerificationCode(3)"
                              :disabled="isForgetCountingDown"
                              :style="isVaild ? getCodeBtnStyle:''"
                              >
                              {{ isForgetCountingDown ? ForgetCountdown + "秒" : '获取验证码' }}
                            </el-button>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 20px" prop="password">
                        <el-input v-model="resetPasswordForm.password" size="large" show-password placeholder="请输入8-16位包含数字和字母组合">
                        </el-input>
                      </el-form-item>
                      <el-form-item style="margin-bottom: 20px" prop="confirmPassword">
                        <el-input v-model="resetPasswordForm.confirmPassword" size="large" show-password placeholder="请输入8-16位包含数字和字母组合">
                        </el-input>
                      </el-form-item>
                      <el-form-item style="width: 100%;">
                        <el-button type="primary" size="large" style="width: 100%;" @click="confirmLogin">确认并登录</el-button>
                      </el-form-item> 
                </el-form>
                <div class="register-info">
                  <p style="font-size: small;text-align: left;"><span style="font-size: small;color: blue;" @click="toLogin">返回登录</span></p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 滑动验证 -->
    <div class="vcode-overlay">
       <Vcode :show="showSlideVerification" @success="onSuccess" @close="onClose"/>
    </div>
    <!-- 分界线 -->
    <div class="boundary"></div>
  </div>
</template>
<script>
import { ElButton ,ElDialog,ElInput,ElForm,ElFormItem,ElMessage , ElDropdown , ElDropdownMenu,ElDropdownItem,ElCheckbox,ElMessageBox} from 'element-plus';
import { getSmsCode,loginOrRegister,resetPassword } from '@/api';
import {Close,Search,User,Setting,Tickets,House,Help,PriceTag,Phone} from "@element-plus/icons"
import { HttpCodes ,StatusCodes} from '@/common/statusCodes';
import Vcode from "vue3-puzzle-vcode";
export default {

  name: 'top-navigator',
  components:{
      ElButton,
      ElDialog,
      ElInput,
      ElForm,
      ElFormItem,
      ElDropdownItem,
      ElDropdown,
      ElDropdownMenu,
      ElCheckbox,
      Close,
      Search,
      User,
      Setting,
      Tickets,
      House,
      Help,
      PriceTag,
      Phone,
      Vcode

  },
  data(){
      return{
          showSlideVerification:false,
          // 验证成功之后执行哪个操作，登录、注册...
          onSuccessEvent:"",
          loginStatus:false,
          dialogVisible:false,
          innerVisible:false,
          innerTitle:"",
          showLogin:true,
          isLoging:false,
          loginForm:{
            phone: '',
            verificationCode: '',
            arrgeMent:"",
            password:""
          },
          rules:{
            verificationCode :{required:true,message:"验证码不能为空",trigger:"blur"},
            phone:{required:true, validator:this.validPhone, trigger:"blur"},
            password:{required:true,validator: this.validPassword, trigger: 'blur'},
          },
          isRegistering:false,
          registerForm:{
            phone: '',
            verificationCode: '',
            password:"",
            confirmPassword:"",
            arrgeMent:"",
          },
          registerRules:{
            phone:{required:true, validator:this.validPhone, trigger:"blur"},
            password:{ required: true,validator: this.validPassword, trigger: 'blur'},
            confirmPassword:{
              required:true,
              validator: (rule, value, callback) => this.validConfirmPassword(rule, value, callback, 'register'), 
              trigger: 'blur'},
          },
          resetPasswordForm:{
            phone: '',
            verificationCode: '',
            password:"",
            confirmPassword:""
          },
          resetPsswordRules:{
            phone:{required:true, validator:this.validPhone, trigger:"blur"},
            password:{ required:true,validator: this.validPassword, trigger: 'blur'},
            confirmPassword:{
              required:true,
              validator: (rule, value, callback) => this.validConfirmPassword(rule, value, callback, 'reset'), 
              trigger: 'blur'},
          },
          // 登录六十秒倒计时
          LoginCountdown: 60,
          isLoginCountingDown: false,
          // 注册
          RegisterCountdown: 60,
          isRegisterCountingDown: false,
          // 忘记密码
          ForgetCountdown:60,
          isForgetCountingDown:false,
          searchQuery:"",
          new_user:null,
          isVaild:false,
          getCodeBtnStyle:{
            backgroundColor:"#409EFF",
            borderColor: "#409EFF",
            color: "white",
          },
          show_top:false,
          isLogin:true,
          isRegister:false,
          isforgetPassword:false,
          activeTab: 'login', // 默认选中“登录”,
          isPhoneLogin:false , // 是否是手机号验证登录
          fileContent:"",
          selectedView:null,
          isMessageVisible:false,
          
        }
  },
  methods: {
      // 导航去其它页面
      goToRoutes(route){
        this.$router.push(route);
      },
      selectView(view){
        this.selectedView = view
      },
      // 验证成功的回调
      onSuccess(){
        this.showSlideVerification = false
        // 登录
        if(this.onSuccessEvent == "login"){
            let loginData = {
            is_new_user: false,
            code: "",
            mobile: this.loginForm.phone,
            password:""
          }
          if(this.loginForm.password){
            loginData.password = this.loginForm.password
            delete loginData.code
          }
          if(this.loginForm.verificationCode){
            loginData.code = this.loginForm.verificationCode
            delete loginData.password
          }
          loginOrRegister(loginData).then(response => {
            // 登陆成功保存用户的信息到vuex
            const { drawsettings, ...userInfo } = response.data.data.re_dict
            const removedKeyValuePair = { drawsettings };
            const now = Date.now();
            // 超时时间为refresh_token的有效时间，后端refresh_token配置的三天之内有效
            const millisecondsInThreeDays = 3 * 24 * 60 * 60 * 1000;
            const timestampThreeDaysLater = now + millisecondsInThreeDays;
            userInfo.timeout = timestampThreeDaysLater
            this.$store.commit("setUserInfo",userInfo)
            this.$store.commit("setDrawsettings",removedKeyValuePair.drawsettings)
            if(response.status == HttpCodes.SUCCESS){
              this.onSuccessEvent == ""
              this.isLoging = false
              this.showMessage("登陆成功","success")
              // 登录成功跳转至个人首页
              this.$router.push("/personal")
            }
            }).catch(error => {
              this.onSuccessEvent == ""
              this.isLoging = false
              // 用户不存在、密码错误等，给与提示
              this.showMessage(error.response.data.data.error,"warning")
          });
        }
        // 注册
        if(this.onSuccessEvent == "register"){
          let registerData = {
            is_new_user: true,
            code: this.registerForm.verificationCode,
            mobile: this.registerForm.phone,
            password:this.registerForm.password,
          }
          loginOrRegister(registerData).then(response => {
            if(response.status == HttpCodes.CREATED){
              this.onSuccessEvent == ""
              this.isRegistering= false
              this.showMessage("注册成功，请登录","success")
              this.toLogin()
            }
          }).catch(error => {
            this.onSuccessEvent == ""
            this.isRegistering= false
            this.showMessage(error.response.data.data.error,"warning")
          })
        }
        // 忘记密码
        if(this.onSuccessEvent == "forgetPassword"){
          let resetForm = {
            is_new_user: true,
            code: this.resetPasswordForm.verificationCode,
            mobile: this.resetPasswordForm.phone,
            password:this.resetPasswordForm.password
          }
          resetPassword(resetForm).then(response => {
            if(response.status == HttpCodes.SUCCESS){
              this.onSuccessEvent = ""
              this.showMessage("已成功修改密码,并成功登陆","success")
              const { drawsettings, ...userInfo } = response.data.data.re_dict
              const removedKeyValuePair = { drawsettings };
              const now = Date.now();
              // 超时时间为refresh_token的有效时间，后端refresh_token配置的三天之内有效
              const millisecondsInThreeDays = 3 * 24 * 60 * 60 * 1000;
              const timestampThreeDaysLater = now + millisecondsInThreeDays;
              userInfo.timeout = timestampThreeDaysLater
              this.$store.commit("setUserInfo",userInfo)
              this.$store.commit("setDrawsettings",removedKeyValuePair.drawsettings)
              // 登录成功跳转至个人首页
              this.$router.push("/personal")
              location.reload()
            }
          }).catch(error => {
            this.onSuccessEvent = ""
            this.showMessage(error.response.data.message,"warning")
          })
        }
      },
      // 关闭时的回调
      onClose(){
        this.isLoging = false
        this.isRegistering = false
        this.showSlideVerification = false
      },
      // 获取高亮显示的导航元素
      getSelectedView(){
        const routeMap = {
          "/personal": "personal",
          "/home": "personal",
          "/pricing": "pricing",
          "/help": "help",
          "/contact": "contact"
        };

        // 根据当前路由路径设置 selectedView
        this.selectedView = routeMap[this.$route.path] || "default";

      },
      // 获取验证码
      getVerificationCode(num) {
        // 1为登录获取验证码
        // 2为注册获取验证码
        // 3为忘记密码获取验证码
        if(num == 1){
          if(this.isValidPhone(this.loginForm.phone)){
            this.sendVerificationCode(this.loginForm.phone,num,false)
          }else{
            this.showMessage("请输入合法手机号","warning")
          }
        }
        if(num == 2){
          if(this.isValidPhone(this.registerForm.phone)){
            this.sendVerificationCode(this.registerForm.phone,num,true)
          }else{
            this.showMessage("请输入合法手机号","warning")
          }
        }
        if(num == 3){
          if(this.isValidPhone(this.resetPasswordForm.phone)){
            this.sendVerificationCode(this.resetPasswordForm.phone,num,false)
          }else{
            this.showMessage("请输入合法手机号","warning")
          }
        }
      },
      // 获取验证码函数，传入手机号
      sendVerificationCode(phone,num,is_new_user){
        getSmsCode({
            phone:phone,
            is_new_user:is_new_user
          }).then(response => {
            if(response.status == HttpCodes.SUCCESS){
                this.showMessage(response.data.data.info,"success")
                // 控制那个计时器开始
                this.startCountdown(num)
              }
            }).catch(error => {
            // 验证码发送频繁
            if(error.response.data.code == StatusCodes.SMS_CODE_THROTTLING_ERR){
              ElMessageBox.confirm(
                 error.response.data.message,
                '消息提示',
                {
                  confirmButtonText: '确定',
                  customClass: 'custom-message-box',
                  showCancelButton: false, // 显示取消按钮
                }
              ).then(() => {
                this.showMessage("请一分钟后再重试",'info')
                }).catch(() => {
                  this.showMessage("操作已取消",'info')
                })
            }
            // 未注册直接进行登录情况,或者手机号已存在进行注册
            if(error.response.status == HttpCodes.BAD_REQUEST && error.response.data.code != StatusCodes.SMS_CODE_THROTTLING_ERR){
              this.showMessage(error.response.data.data.error,"warning")
              return
            }
        })
      },
      // 控制六十秒倒计时
      startCountdown(num) {
        // 1代表是短信登录获取验证码
        // 2代表是注册获取验证码
        // 3代表是找回密码获取验证码
        if(num == 1){
          this.isLoginCountingDown = true;
          this.LoginCountdown = 60;
          const interval = setInterval(() => {
            this.LoginCountdown--;
            if (this.LoginCountdown <= 0) {
              clearInterval(interval);
              this.isLoginCountingDown = false;
            }
          }, 1000);
        }
        // 注册
        if(num == 2){
          this.isRegisterCountingDown = true
          this.RegisterCountdown = 60
          const interval = setInterval(() => {
            this.RegisterCountdown--;
            if (this.RegisterCountdown <= 0) {
              clearInterval(interval);
              this.isRegisterCountingDown = false;
            }
          }, 1000);

        }
        // 忘记密码
        if (num == 3){
          this.isForgetCountingDown = true
          this.ForgetCountdown = 60
          const interval = setInterval(() => {
            this.ForgetCountdown--;
            if (this.ForgetCountdown <= 0) {
              clearInterval(interval);
              this.isForgetCountingDown = false;
            }
          }, 1000);
        }
      },
      isValidPhone(phone) {
        const isPhone = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/ //11位合法手机号码
        const isMob1 = /0\d{2,3}\d{7,8}/ //不带-的座机：02985111752
        const isMob2 = /^([0-9]{3,4}-)?[0-9]{7,8}$/ //带一个-：029-85111752
        const isMob3 = /^\d{3,4}-\d{3,4}-\d{3,4}$/; //带三个-： 0752-250-520
        if (isMob1.test(phone) || isMob2.test(phone) || isMob3.test(phone) || isPhone.test(phone)) {
          return true
        } else {
          return false
        }
      },
      // 判断手机号是否合法
      validPhone(rule, value, callback){
            if (!value) {
              this.isVaild = false
              callback(new Error("手机号不能为空"))
            } else if (!this.isValidPhone(value)) {
              this.isVaild = false
              callback(new Error('请输入合法的电话号码'))
            } else {
              this.isVaild = true
              callback()
            }
      },
      // 判断密码是否合法
      validPassword(rule, value, callback) {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
        if (!value) {
          callback(new Error('请输入密码'));
        } else if (!passwordRegex.test(value)) {
          callback(new Error('密码必须是8到16位的字母和数字组合'));
        } else {
          callback();
        }
      },
      // 判断确认密码和密码是否一致
      validConfirmPassword(rule, value, callback, passwordField) {
        // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
        let form = passwordField === 'register' ? this.registerForm : this.resetPasswordForm;
        if (!value) {
          callback(new Error('请输入确认密码'));
        } else if (value != form.password) {
          callback(new Error('请再次确认新密码'));
        } else {
          callback();
        }
      },
      // 验证码效验规则，为六位纯数字
      validateCode(rule, value, callback){
        if (!value) {
          return callback(new Error('请输入验证码'));
        }
        if (!/^\d{6}$/.test(value)) {
          return callback(new Error('验证码必须是6位数字'));
        }
        callback();
      },
      // 登录逻辑
      login() {
          this.isLoging = true
          this.$refs.loginForm.validate((valid) => {
            if (valid) {
              if(!this.loginForm.phone){
                this.showMessage("请输入手机号","warning")
                return
              }
              this.onSuccessEvent = "login"
              this.showSlideVerification = true
            }
          });
      },
      // 跳转至微信登录
      wechat(){
        this.$router.push("/wxlogin")
      },
      // 打开服务协议
      openInner(index){
        if(index==0){
          this.innerVisible = true
          this.innerTitle = "请仔细阅读服务协议"
          fetch('/files/service_agreement.txt')
            .then(response => response.text())
            .then(data => {
              this.fileContent = data; // 将文件内容保存到data中
            })
            .catch(error => {
              console.error('Error loading the file:', error);
          });
        }else{
          this.innerVisible = true
          this.innerTitle = "请仔细阅读隐私政策"
          fetch('/files/privacy_policy.txt')
            .then(response => response.text())
            .then(data => {
              this.fileContent = data; // 将文件内容保存到data中
            })
            .catch(error => {
              console.error('Error loading the file:', error);
          });

        }
      },
      closeInnerDialog(){
        this.innerVisible = false
      },
      toggleSelection(value) {
        if (this.loginForm.arrgeMent === value) {
          // 如果当前选择的值等于传入的值，则取消选择
          this.loginForm.arrgeMent = null;
        } else {
          // 否则，将当前选择的值设置为传入的值
          this.loginForm.arrgeMent = value;
        }
      },
      // 退出登录的逻辑清空localStorage
      exit(){
        localStorage.clear()
        location.reload()
      },
      // 顶部搜索框
      search(){
        if(this.searchQuery){
          // TODO:搜索关键字存储到vuex中,在搜索页面进行搜索
          this.$store.commit("setAssetKeyword", this.searchQuery)
          this.$router.push("/search")
        }else{
          this.showMessage("查询内容不能为空",'warning')
        }
      },
      showMessage(message, type) {
        if (this.isMessageVisible) return;
        this.isMessageVisible = true;

        ElMessage({
          message: message,
          type: type,
          plain: true,
          onClose: () => {
            this.isMessageVisible = false;
          }
        });
      },
      // 显示ai图额度不足提示
      clodeAd(){
        this.show_top = false
      },
      // 控制登录注册切换
      selectTab(tab) {
        this.activeTab = tab;
        this.isLogin = !this.isLogin
        this.isRegister =  !this.isRegister
        if(tab == "login"){
          this.isLogin = true
          this.isRegister = false
        }else{
          this.isLogin = false
          this.isRegister = true
        }
      },
      // 密码或者手机登录切换
      phoneLogin(){
      this.isPhoneLogin = !this.isPhoneLogin
      // 如果是手机号验证码登录，就清空密码
      if(this.isPhoneLogin){
        this.loginForm.password = ""
      }else{
        this.loginForm.verificationCode = ""
      }
      },
      // 忘记密码
      forgetPassowrd(){
        this.isforgetPassword = !this.isforgetPassword
        this.isLogin = !this.isLogin
      },
      // 注册主逻辑
      register(){
        this.isRegistering = true
        this.$refs.registerForm.validate((vaild) => {
          if(vaild){
            if(!this.registerForm.phone){
              this.showMessage("请输入手机号","warning")
              return
            }
            if(!this.registerForm.verificationCode){
              this.showMessage("请输入验证码","warning")
              return
            }
            this.onSuccessEvent = 'register'
            this.showSlideVerification = true
          }
        })
      },
      // 重置密码,并且登录
      confirmLogin(){
        this.$refs.resetPasswordForm.validate((vaild) => {
          if(vaild){
            if(!this.resetPasswordForm.phone){
              this.showMessage("请输入手机号","warning")
              return
            }
            if(!this.resetPasswordForm.verificationCode){
              this.showMessage("请输入验证码","warning")
              return
            }
            this.onSuccessEvent = 'forgetPassword'
            this.showSlideVerification = true
          }
        })
      },
      // 注册完成去登录
      toLogin(){
        this.isLogin = true
        this.isforgetPassword = false
        this.isRegister = false
        this.activeTab = 'login'
      }
    },
  mounted(){
    this.getSelectedView()

    this.loginStatus = this.$store.getters.token
    this.showLogin = !this.loginStatus
  }

}
</script>
<style>
/* 确保滑动验证在对话框上面 */
.vue-puzzle-vcode.show_ {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999 !important;
}

/* TopBar底部分界线 */
.boundary{
  height: 1px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 1px;
  background-color: #e9ebed;
}
/* 用户协议隐私政策标签样式 */
.a-style{
  margin: 0;
  text-decoration: none;
  color: blue;
  font-size: 14px
}
.a-style:hover{
  cursor: pointer;
  background: none;
  color: rgb(0, 0, 128);
}
.my-router-link{
  display: flex;
  width: auto;
  margin: 0 15px;
}
/* 选中的高亮显示 */
.my-router-link.active{
  background: none;
  color: #409eff;
}
.my-router-link:hover{
  background: none;
  color: #409eff;
}
.login-btn-style{
  border-radius: 10px !important;
  border: none !important;
}
.dropdown-style img:hover{
  border: none !important;
}
.dropdown-item-style{
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}
.dropdown-item-style span {
  color: darkgray;
}
.dropdown-item-style:hover span {
  color: #409eff; /* 悬停时的颜色 */
}


.example-showcase .el-dropdown-link {
cursor: pointer;
color: var(--el-color-primary);
display: flex;
align-items: center;
}
.search_box{
  display: inline-flex;
  width:500px;
  height:30px;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px
}
/* 搜索框容器 */
.custom-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
}

/* 搜索i图标 */
.custom-icon-container el-icon {
  display: flex;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  color: black;
}
/* 自定义输入框样式 */
.custom-input-wrapper .el-input__wrapper {
  border-radius: 10px 0px 0px 10px  !important;
  background-color: #f7f9fb; /* 自定义背景色 */
  transition: border-color 0.3s ease, background-color 0.3s ease;
}
/* 改变插槽默认样式 */
.custom-input-wrapper .el-input-group__append{
  border-radius: 0px 10px 10px 0px  !important;
  background-color:rgb(227, 159, 13) !important;
  box-shadow: none !important;
  padding:0 !important
}
/* 搜索插槽鼠标放上去样式 */
.custom-input-wrapper .el-input-group__append:hover{
  cursor: pointer;
}
.floating-input .el-input__wrapper.is-focus{
  box-shadow: 0 0 0 1px rgb(227, 159, 13) inset;
  }
  
.more{
  font-weight: bold;
  font-size: small;
}
.more:hover{
  cursor: pointer;
  color: white;
}
.close-icon{
  height: 20px; 
  width: 20px; 
  position: absolute; 
  right: 10px;
}
.close-icon:hover{
  cursor: pointer;
  color: white;
}

.title {
  margin-left: 0px;
  height: 55px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.title-left {
  width: 580px;
  height: 48px;
  margin-left: 0;
  background: #fff;
  display: flex;

}

.title-left-image {
  height: 24px;
  width: 60px
}

span {
  font-size: 16px
}

a {
  width: 70px;
  height: 48px;
  color: black;
  margin: 0px 10px;
  text-decoration: none;
  line-height: 48px;
  text-align: center
}

a:hover {
  background-color: #BEBEBE;
}

.profile-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dialog {
padding: 0 !important;
}
.el-dialog__header {
  padding-bottom: 0 !important;
}


.dialog-content {
  margin: 0px;
  display: flex;
  justify-content: flex-end; /* 右对齐 */
  background-image: url("https://img1.baidu.com/it/u=2803269788,456743659&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500");
}
.other-logins{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
}
.other-login{
  display: flex;
  height: 40px;
  border: 1px solid #adafb1;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.other-login:hover{
  cursor: pointer;
  background-color: rgb(218, 218, 209)
}
.other-login img{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px
}
.register-info{
  margin-top: 20px;
}
.register-info p span:hover{
  cursor: pointer;
}

.right-content {
float: right;
width: 450px; /* 设置右边内容宽度 */
margin: 20px;
margin-top: 10px;
border: 1px solid black;
background: white;
}
.dialog-footer {
text-align: right;
}

.other-methods::before,
.other-methods::after {
content: '';
position: absolute;
top: 50%;
width: 40%;
height: 1px;
background-color: #ccc; /* 修改颜色为浅灰色 */

}

.other-methods::before {
left: 0;
}

.other-methods::after {
right: 0;
}

.login-options {
display: flex;
justify-content: center;
margin-top: 20px;
}
.forget-password{
  display: flex; 
  justify-content: space-between; 
  width: 100%;
  margin-left: 50px;
  margin-right: 50px
}
.forget-password span{
  color: blue;
  font-size: small;
}
.forget-password span:hover{
  cursor: pointer;
  color: #848181;
}

.other{
align-items: center;
text-align: center;
border:1px dashed black;
border-radius: 10px;
margin-left: 50px;
margin-right: 50px;
margin-bottom: 50px;
}
.custom-message-box {
top: 25% !important; /* 调整这个值以改变弹出窗的位置 */
transform: translate(-50%, -20%) !important; /* 使其水平居中 */
position: fixed !important; /* 确保位置固定 */
}

.switch-login-btn{
  margin-bottom: 10px;
  display: flex; 
  justify-content: center; 
}
.switch-login-btn h1 {
  margin: 20px 30px;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 4px solid transparent;
  transition: border-bottom 0.3s;
}

.switch-login-btn .selected {
  border-bottom: 4px solid blue;
}
.dialog-container {
  margin-top: 50px;
  height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}


.file-content {
  flex: 1; /* 占据剩余空间 */
  width: 96%;
  max-height: 60%;
  white-space: pre-wrap; /* 保留空格和换行 */
  font-family: monospace; /* 使用等宽字体，使文本格式更接近原始文件 */
  padding: 10px;
  overflow-y: auto; /* 如果内容太多，则显示垂直滚动条 */
}

.dialog-btn {
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
  flex-shrink: 0; /* 防止按钮缩小 */
  color: rgb(73, 100, 32);
}
.dialog-btn:hover{
  color: rgb(51, 78, 10);
}
.el-input__inner{
  background: none !important;
}

</style>

