<template>
  <div>
    <top-navigator>top bar</top-navigator>
    <div class="contact-container">
        <div class="contact-form-container"> 
            <div class="form-title">
                <h1>企业合作</h1>
            </div>
            <el-form :model="contactForm" label-position="top" :rules="contactFormRules" ref="contactForm">
                <el-form-item  prop="companyName" class="contact-item"> 
                    <template v-slot:label>
                      <span style="font-size: large;">1.公司名称</span>
                    </template>
                    <el-input v-model="contactForm.companyName" placeholder="请输入公司名称" size="large"></el-input>
                </el-form-item>
                <el-form-item prop="industry" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">2.所在行业</span>
                    </template>
                    <el-input v-model="contactForm.industry" placeholder="请输入行业" size="large"></el-input>
                </el-form-item>
                <el-form-item prop="collaborationNeeds" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">3.合作需求</span>
                    </template>
                    <el-input 
                        v-model="contactForm.collaborationNeeds" 
                        placeholder="请输入合作需求" 
                        size="large" 
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 6 }"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="contactPersonName" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">4.联系人姓名</span>
                    </template>
                    <el-input v-model="contactForm.contactPersonName" placeholder="请输入联系人姓名" size="large"></el-input>
                </el-form-item>
                <el-form-item prop="contactPersonPhone" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">5.联系人手机号</span>
                    </template>
                    <el-input v-model="contactForm.contactPersonPhone" placeholder="请输入联系人手机号" size="large"></el-input>
                </el-form-item>
                <el-form-item prop="contactPersonPosition" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">6.联系人职位</span>
                    </template>
                    <el-input v-model="contactForm.contactPersonPosition" placeholder="请输入联系人职位" size="large"></el-input>
                </el-form-item>
                <el-form-item prop="contactPersonEmail" class="contact-item">
                    <template v-slot:label>
                      <span style="font-size: large;">7.联系人邮箱</span>
                    </template>
                    <el-input v-model="contactForm.contactPersonEmail" placeholder="请输入联系人邮箱" size="large" ></el-input>
                </el-form-item>
            </el-form>
            <div class="contact-container-bottom">
              <div class="submit-contact-btn" @click="submitContactForm">
                  <span>提交</span>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { createContact } from "@/api";
import { ElForm, ElFormItem, ElInput,ElMessage} from "element-plus";
import TopNavigator from "@/components/TopBar.vue";
import { HttpCodes } from "@/common/statusCodes";
export default {
    name: "contact-us",
    components:{
      TopNavigator,
      ElForm,
      ElFormItem,
      ElInput,
    },
    data() {
        return {
           contactForm: {
              companyName: null,
              industry: null,
              collaborationNeeds: null,
              contactPersonName: null,
              contactPersonPhone: null,
              contactPersonEmail: null,
              contactPersonPosition: null
           },
           contactFormRules: {
              companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
              industry: [{ required: true, message: "请输入行业", trigger: "blur" }],
              collaborationNeeds: [{ required: true, message: "请输入合作需求", trigger: "blur" }],
              contactPersonName: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
              contactPersonPhone: [{ required: true, message: "请输入联系人手机号", trigger: "blur" },
              { 
                pattern: /^1[3-9]\d{9}$/, // 手机号的正则表达式
                message: "请输入有效的手机号", 
                trigger: "blur"
              }
              ],
              contactPersonEmail: [
                { required: false,  trigger: "blur" },
                { trigger: "blur", validator: this.validEmail}
              ]
              // contactPersonPosition: [{ required: true, message: "请输入联系人职位", trigger: "blur" }]
           },
        }
    },
    methods: {
      submitContactForm() {
        this.$refs.contactForm.validate((valid) => {
          if (valid) {
            createContact({
              company_name:this.contactForm.companyName,
              industry:this.contactForm.industry,
              collaboration_needs:this.contactForm.collaborationNeeds,
              contact_person_name:this.contactForm.contactPersonName,
              contact_person_phone:this.contactForm.contactPersonPhone,
              contact_person_position:this.contactForm.contactPersonPosition,
              contact_person_email:this.contactForm.contactPersonEmail
            }).then(response => {
              if(response.status == HttpCodes.SUCCESS){
                ElMessage({
                  message:"提交成功",
                  type:"success"
                })
                Object.keys(this.contactForm).forEach(key => {
                  this.contactForm[key] = null; // 设置属性为 null
                });
              }
            }).catch(error => {
              console.log("Submit contact form error:",error)
            })
          }
        });
      },
      // 邮箱格式效验
      validEmail(rule, value, callback) {
        const pattern =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (!value) {
           // 如果没有输入值，直接通过验证
          callback();
        } else if (!pattern.test(value)) {
          // 如果输入值不符合邮箱格式，返回错误信息
          callback(new Error("请输入有效的邮箱地址"));
        } else {
        // 如果输入值符合邮箱格式，直接通过验证
          callback();
          }
        }
    }
};
</script>

<style scoped>
.contact-container {
  display: flex;
  width: 100%;
  background-color: rgb(246, 248, 250);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px
}

.form-title {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-bottom: 10px;
}

.contact-form-container {
  padding: 50px 50px 0px 50px;
  width: 60%;
  max-width: 720px; /* 确保表单不会太宽 */
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.contact-container-bottom{
  max-width: 600px; /* 确保表单不会太宽 */
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding: 20px 50px 40px 50px;
  background-color: white;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
}


.submit-contact-btn {
  width: 300px;  /* 固定按钮宽度 */
  height: 40px;
  background-color: #1f883d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.submit-contact-btn span{
  color: white;
}

.submit-contact-btn:hover {
  background-color: #1e7737; /* 提交按钮的 hover 效果 */
}

.contact-item{
  margin-bottom: 30px;
}
/* 修改el-input内部样式 */
:deep(.el-input__wrapper) {
  max-width: none;
}
</style>
