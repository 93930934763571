<template>
     <div class="infoLeft">
        <div class="menu">
            <!-- 订单导航 -->
            <div class="menuItem"
                :class="{ active: selectedMenu === 'order' }"
                @click="selectMenu('order')"
                >
                <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px" :class="{isBule:selectedMenu == 'order'}"><Tickets /></el-icon>
                <span :style="{ color: selectedMenu == 'order' ? 'blue' : 'black' }" style="white-space: nowrap;">订单</span>
            </div>
            <!-- 个人中心导航 -->
            <div class="menuItem"
                :class="{ active: selectedMenu === 'center' }"
                @click="selectMenu('center')">
                <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px" :class="{isBule:selectedMenu == 'center'}"><User/></el-icon>
                <span :style="{ color: selectedMenu == 'center' ? 'blue' : 'black' }" style="white-space: nowrap;">个人中心</span>
            </div>
            <!-- 账号设置导航 -->
            <div class="menuItem"
                :class="{ active: selectedMenu === 'account' }"
                @click="selectMenu('account')">
                <el-icon style="width: 20px;height: 20px;display: inline-block;margin-right: 5px" :class="{isBule:selectedMenu == 'account'}"><Setting/></el-icon>
                <span :style="{ color: selectedMenu == 'account' ? 'blue' : 'black' }" style="white-space: nowrap;">账号设置</span>
            </div>
        </div>
    </div>
</template>


<script>
import { User,Tickets,Setting} from "@element-plus/icons";
export default {
    name:"personal-navigator",
    components:{
        User,
        Tickets,
        Setting
    },
    data(){
        return {
            selectedMenu: 'order', // 默认选中的菜单项
        }
    },
    methods:{
        // 改变被选中的导航，去不同页面
        selectMenu(menu) {
            this.selectedMenu = menu;
            const menuMap = {
                order: "/personal/order",
                center: "/personal/center",
                account: "/personal/account",
            };

            const route = menuMap[menu];
            if (route) {
                this.$router.push(route);
            }
        },
        // 获取被选中的menu
        getSelectedMenu() {
            if (this.$route.path.startsWith("/personal/order")) {
                this.selectedMenu = "order";
                return;
            }
            if (this.$route.path.startsWith("/personal/account")) {
                this.selectedMenu = "account";
                return;
            }
            const routeMap = {
                "/personal/center": "center",
            };
            this.selectedMenu = routeMap[this.$route.path] || "";
        }


    },
    mounted(){
        this.getSelectedMenu()
    }

}
</script>

<style>
.infoLeft {
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.menu {
    display: flex;
    flex-direction: column
}
.menuItem {
    display: flex;
    padding: 20px;
    cursor: pointer;
    text-align: left;
    justify-content: left;
}
.menuItem:hover {
    background-color: var(--el-color-primary-light-8);
}
.menuItem.active {
    background-color: var(--el-color-primary-light-9);
}

</style>