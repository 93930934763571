<template>
        <div>
            <div class="time-container">
                <span style="font-weight: bold;">交易时间：</span>
                <ElConfigProvider :locale="zhCn">
                    <el-date-picker
                        v-model="value1"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY年MM月DD日"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        @change="handleDateChange">
                    </el-date-picker>
                </ElConfigProvider>
            </div>
            <div class="table-container">
                <el-table :data="tableData" :cell-style="getCellStyle" empty-text="暂无订单">
                    <el-table-column prop="order_sn" label="订单号" width="320"/>
                    <el-table-column prop="created_at" label="订单创建时间" width="220" />
                    <el-table-column prop="subscribe_type" label="产品名称" width="210">
                        <template v-slot="scope">
                            <span>{{ getSubscriptionLabel(scope.row.subscribe_type) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payment_type" label="订单支付类型" width="180">
                        <template v-slot="scope">
                            <div v-if="scope.row.payment_type === 'alipay'">
                                <span>支付宝</span>
                            </div>
                            <div v-else-if="scope.row.payment_type==='wxpay'">
                                <span>微信支付</span>
                            </div>
                            <div v-else>
                                <span>对公付款</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_status" label="订单状态" width="180">
                        <template v-slot="scope">
                            <div v-if="scope.row.pay_status === 'TRADE_SUCCESS' || scope.row.pay_status === 'SUCCESS'" style="color: green;">
                                <span class="dot green"></span> 已支付
                            </div>
                            <div v-else style="color: red;">
                                <span class="dot red"></span> 未支付
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_money" label="订单金额" width="180">
                        <template v-slot="scope">
                            <div>
                                <span>￥</span>{{ scope.row.order_amount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template #default="scope">
                            <span @click="showDetail(scope.$index, scope.row)" class="oper">
                                查看详情
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="order-pagination">
                <ElConfigProvider :locale="zhCn">
                    <el-pagination
                        :page-sizes="[10,20,30,50,100]"
                        layout="total,prev,pager,next,sizes,jumper"
                        :total="totalOrders"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :size="pageData.page_size"
                    />
                </ElConfigProvider>
            </div>
        </div>
</template>

<script>
import { getOrders } from "@/api";
import { ElDatePicker,ElConfigProvider ,ElTable,ElTableColumn,ElPagination} from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { HttpCodes } from "@/common/statusCodes";
export default {
    name: "order-list-page",
    components:{
        ElDatePicker,
        ElConfigProvider,
        ElTable,
        ElTableColumn,
        ElPagination
    },
    data() {
        return {
            zhCn,
            value1:"",
            tableData:[],
            begin_time:"",
            end_time:"",
            order_info:{},
            // 订单总个数
            totalOrders:null,
            pageData:{
                page_size:10,
                page:1
            }
        };
    },
    methods:{
        // 时间选择触发的回调函数
        handleDateChange(value) {
            if(! value){
                return
            }else{
                this.begin_time = value[0]
                this.end_time = value[1]
                this.getOrderList()
            }
        },
        // 自定表格列
        getCellStyle({ column }) {
            if (column.property === 'pay_money') {
                return {
                    color: 'red' 
                };
            }
            return {};
        },
        // 查看订单详情
        showDetail(index,row){
            this.$store.commit("setSelectOrder",row)
            this.$router.push("/personal/order/detail")
            // this.order_info = row
            // if(["MONTHLY","MONTHLY_PRO"].includes(this.order_info.subscribe_type)){
            //     this.order_info.cau_method = "月费"
            // }else if(['SEASON','SEASON_PRO'].includes(this.order_info.subscribe_type)){
            //     this.order_info.cau_method = "季费"
            // }else{
            //     this.order_info.cau_method = "年费"
            // }
        },
        // 获取用户订单列表
        getOrderList(){
            getOrders({
                page:this.pageData.page,
                page_size:this.pageData.page_size,
                begin_time:this.begin_time,
                end_time:this.end_time
            }).then(response => {
               if(response.status == HttpCodes.SUCCESS){
                this.tableData = response.data.data.orders
                this.totalOrders = response.data.data.count
               }
            }).catch(error => {
                console.log("Get user order list error",error)
            })
        },
        // 改变分页大小
        handleSizeChange (page_size){
            this.pageData.page_size = page_size
            this.getOrderList()
        },
        // 变换页码
        handleCurrentChange(page){
            this.pageData.page = page
            this.getOrderList()
        },
        // 获取定于类型
        getSubscriptionLabel(type) {
            const labels = {
                MONTHLY: 'Basic月费',
                MONTHLY_PRO: 'Pro月费',
                SEASON: 'Basic季费',
                SEASON_PRO: 'Pro季费',
                YEARLY: 'Basic年费',
                YEARLY_PRO: 'Pro年费',
            };
            return labels[type] || '会员充值';
        },


    },
    mounted(){
        this.getOrderList()
    }
};
</script>
<style>
html, body, #app {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
}
.time-container{
    display: flex;
    margin-bottom: 20px;
    width: 40%;
    justify-content: center;
    align-items: center;
}

.el-table__header th {
  background-color: #f6f8fa !important;
  /* color: #333 !important;  */
  font-weight: bold !important
}
.oper {
    font-size: small;
    color: blue;
}

.oper:hover {
    cursor: pointer !important;
    background: white;
}
.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}
.order-pagination{
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}
</style>